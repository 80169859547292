import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import {
  Box,
  Stack,
  Button,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import Facet from '../UI/Facet';
import { applyFilters, searchResults } from '../../actions/searchActions';

const formatCourtName = (courtName) => {
  return courtName
    .toLowerCase()
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const revertCourtName = (formattedCourtName) => {
  return formattedCourtName?.toUpperCase().replace(/\s+/g, '-');
};

const transformFacets = (facets) => {
  const transformed = [];

  if (facets.Section) {
    transformed.push({
      category: 'Journal',
      options: facets.Section.map((section) => section.value),
    });
  }

  if (facets.Court) {
    const uniqueCourts = new Set();
    facets.Court.forEach((court) => {
      const courtName = court.value
        ? formatCourtName(court.value.trim())
        : null;
      if (courtName) {
        uniqueCourts.add(courtName);
      }
    });
    transformed.push({
      category: 'Court',
      options: Array.from(uniqueCourts),
    });
  }
  if (facets.Year) {
    transformed.push({
      category: 'Year',
      options: facets.Year.map((year) => year.value),
    });
  }

  transformed.push({
    category: 'Page',
    options: [],
  });

  if (facets.Judge_Name) {
    const uniqueJudges = new Map();

    facets.Judge_Name.forEach((judge) => {
      const originalValue = judge.value && judge.value.trim();

      if (originalValue && originalValue.length > 0) {
        let formattedValue = originalValue
          .replace(/\b(Honourable Justice|Honorable Justice|Justice)\b\s*/i, '')
          .replace(/^[.,&]\s*/g, '')
          .replace(/\b\(\w+\)\b/g, ' ')
          .trim()
          .replace(/\b\w+(-\w+)*\b/g, (word) =>
            word
              .split('-')
              .map(
                (part) =>
                  part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
              )
              .join('-')
          )
          .replace(/\s{2,}/g, ' ')
          .trim();

        if (
          formattedValue &&
          formattedValue.length > 4 &&
          !/^\d/.test(formattedValue) &&
          formattedValue !== '--'
        ) {
          uniqueJudges.set(formattedValue, originalValue);
        }
      }
    });

    transformed.push({
      category: 'Judge',
      options: Array.from(uniqueJudges.entries()).map(
        ([formatted, original]) => ({
          formatted,
          original,
        })
      ),
    });
  }

  if (facets.Lawyer_Name) {
    const uniqueLawyers = new Set();
    facets.Lawyer_Name.forEach((lawyer) => {
      const lawyerName =
        lawyer.value && lawyer.value.trim().length > 0
          ? lawyer.value.replace(/^,/, '').trim()
          : null;
      if (lawyerName) {
        uniqueLawyers.add(lawyerName);
      }
    });
    transformed.push({
      category: 'Lawyer',
      options: Array.from(uniqueLawyers),
    });
  }

  return transformed;
};

const LegalSearchSidebar = ({
  searchKeyword,
  currentPage,
  setCurrentPage,
  isNewCaseLawSearch,
  setIsNewCaseLawSearch,
  searchMode,
  sortBy,
  resultsCount,
}) => {
  const dispatch = useDispatch();

  // const searchFacets = useSelector((state) => state.searchFacets);
  // const { loading, facets } = searchFacets;

  const searchResultsState = useSelector((state) => state.searchResults);
  const { results } = searchResultsState;

  const searchSelectedFilters = useSelector((state) => state.selectedFilters);
  const { selectedFilters } = searchSelectedFilters;

  const caseFilters = useSelector((state) => state.caseFilters);
  const { filters: statuteRelevantCaseFilters } = caseFilters;

  const [facetValues, setFacetValues] = useState([]);
  const [selectedValues, setSelectedValues] = useState({});
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [showChips, setShowChips] = useState({});
  const [selectedFilterMode, setSelectedFilterMode] = useState('standard');

  const handleApplyFilter = () => {
    if (Object.keys(selectedValues).length === 0) return;

    // Update show chip state for each filter
    const updatedShowChips = {};
    Object.keys(selectedValues).forEach((key) => {
      updatedShowChips[key] = true;
    });
    setShowChips(updatedShowChips);
    setIsFilterApplied(true);
    const updatedFilters = {
      ...selectedValues,
      Judge: selectedValues['Judge']?.original,
      Court: revertCourtName(selectedValues['Court']),
    };
    setCurrentPage(1);
    dispatch(
      applyFilters(
        searchKeyword,
        updatedFilters,
        (currentPage - 1) * resultsCount,
        resultsCount,
        searchMode,
        sortBy
      )
    );
    dispatch({
      type: 'SEARCH_SELECTED_FILTERS_SUCCESS',
      payload: { selectedValues, updatedShowChips },
    });
  };

  const handleApplyCitationFilter = () => {
    const updatedFilter = {
      Citation_Name: `${selectedValues['Year']} ${selectedValues['Journal']} ${selectedValues['Page']}`,
    };
    dispatch(
      applyFilters(
        searchKeyword,
        updatedFilter,
        (currentPage - 1) * resultsCount,
        resultsCount,
        searchMode,
        sortBy
      )
    );
    setIsFilterApplied(true);
  };

  const handleClearFilters = () => {
    if (selectedValues && Object.keys(selectedValues).length === 0) return;
    setSelectedValues({});
    if (isFilterApplied) {
      dispatch({ type: 'SEARCH_SELECTED_FILTERS_RESET' });
      dispatch(searchResults(searchKeyword));
      setIsFilterApplied(false);
      setShowChips({});
    }
  };

  // Debounced API call
  const debouncedApiCall = debounce((updatedFilters) => {
    // Trigger API request only when year is valid
    dispatch(
      applyFilters(
        searchKeyword,
        updatedFilters,
        (currentPage - 1) * resultsCount,
        resultsCount,
        searchMode,
        sortBy
      )
    );
  }, 1000);

  const handleChange = (category, value) => {
    const updatedSelectedValues = {
      ...selectedValues,
      [category]: value,
    };

    setSelectedValues((prevSelectedValues) => ({
      ...prevSelectedValues,
      [category]: value,
    }));

    const updatedShowChips = {
      ...showChips,
      [category]: true,
    };

    setShowChips((prevState) => ({
      ...prevState,
      [category]: true,
    }));
    if (selectedFilterMode === 'standard') {
      setIsFilterApplied(true);
    }

    const updatedFilters = {
      ...updatedSelectedValues,
      Judge: updatedSelectedValues?.['Judge']?.original,
      Court: revertCourtName(updatedSelectedValues?.['Court']),
    };
    setCurrentPage(1);
    if (category === 'Year' && selectedFilterMode === 'standard') {
      if (value.length === 4) {
        debouncedApiCall(updatedFilters);
      } else {
        return;
      }
    } else {
      if (selectedFilterMode === 'standard') {
        dispatch(
          applyFilters(
            searchKeyword,
            updatedFilters,
            (currentPage - 1) * resultsCount,
            resultsCount,
            searchMode,
            sortBy
          )
        );
      }
    }
    dispatch({
      type: 'SEARCH_SELECTED_FILTERS_SUCCESS',
      payload: { updatedSelectedValues, updatedShowChips },
    });
  };

  const handleChangeFilterMode = (event, newAlignment) => {
    if (newAlignment !== null) {
      setSelectedFilterMode(newAlignment);
      handleClearFilters();
    }
  };

  const handleClearChip = (category) => {
    const updatedSelectedValues = {
      ...selectedValues,
      [category]: '',
    };
    setSelectedValues((prevSelectedValues) => ({
      ...prevSelectedValues,
      [category]: '',
    }));

    const updatedShowChips = {
      ...showChips,
      [category]: false,
    };

    setShowChips((prevState) => ({
      ...prevState,
      [category]: false,
    }));

    dispatch({
      type: 'SEARCH_SELECTED_FILTERS_SUCCESS',
      payload: { updatedSelectedValues, updatedShowChips },
    });

    if (category === 'Court') {
      const updatedFilters = {
        ...updatedSelectedValues,
        Judge: selectedValues?.['Judge']?.original,
      };
      dispatch(
        applyFilters(
          searchKeyword,
          updatedFilters,
          (currentPage - 1) * resultsCount,
          resultsCount,
          searchMode,
          sortBy
        )
      );
    } else if (category === 'Judge') {
      const updatedFilters = {
        ...updatedSelectedValues,
        Court: revertCourtName(selectedValues?.['Court']),
      };
      dispatch(
        applyFilters(
          searchKeyword,
          updatedFilters,
          (currentPage - 1) * resultsCount,
          resultsCount,
          searchMode,
          sortBy
        )
      );
    } else {
      const updatedFilters = {
        ...updatedSelectedValues,
        Judge: selectedValues?.['Judge']?.original,
        Court: revertCourtName(selectedValues?.['Court']),
      };
      dispatch(
        applyFilters(
          searchKeyword,
          updatedFilters,
          (currentPage - 1) * resultsCount,
          resultsCount,
          searchMode,
          sortBy
        )
      );
    }
  };

  useEffect(() => {
    if (results?.['search_results']?.facets) {
      const transformedFacets = transformFacets(
        results?.['search_results']?.facets
      );
      setFacetValues(transformedFacets);
    }
  }, [results]);

  useEffect(() => {
    if (statuteRelevantCaseFilters) {
      setSelectedValues({
        Sections: [statuteRelevantCaseFilters.section],
        Courts: statuteRelevantCaseFilters.court,
        Year: statuteRelevantCaseFilters.year,
      });
      const selectedFilters = {
        Sections: [statuteRelevantCaseFilters.section],
        Courts: revertCourtName(statuteRelevantCaseFilters.court),
        Year: statuteRelevantCaseFilters.year,
      };
      dispatch(
        applyFilters(statuteRelevantCaseFilters.keyword, selectedFilters)
      );
      setIsFilterApplied(true);
      setShowChips(true);
      dispatch({ type: 'RESET_CASE_FILTERS' });
    }
  }, [statuteRelevantCaseFilters, dispatch]);

  // useEffect(() => {
  //   dispatch(getFacets());
  // }, [dispatch]);

  useEffect(() => {
    if (isFilterApplied) {
      const updatedFilters = {
        ...selectedValues,
        Judge: selectedValues?.['Judge']?.original,
        Court: revertCourtName(selectedValues?.['Court']),
      };

      dispatch(
        applyFilters(
          searchKeyword,
          updatedFilters,
          (currentPage - 1) * resultsCount,
          resultsCount,
          searchMode,
          sortBy
        )
      );
    }
  }, [currentPage, resultsCount, sortBy]);

  // useEffect(() => {
  //   if (facets && facets['facets']) {
  //     const transformedFacets = transformFacets(facets['facets']);
  //     dispatch({
  //       type: 'SEARCH_FILTER_VALUES_SUCCESS',
  //       payload: transformedFacets,
  //     });
  //   }
  // }, [dispatch, facets]);

  useEffect(() => {
    if (searchKeyword === '' || isNewCaseLawSearch) {
      setSelectedValues({});
      setIsFilterApplied(false);
      setShowChips(false);
      setIsNewCaseLawSearch(false);
    }
  }, [searchKeyword, isNewCaseLawSearch, setIsNewCaseLawSearch]);

  useEffect(() => {
    if (selectedFilters) {
      setSelectedValues(selectedFilters.selectedValues);
      setIsFilterApplied(true);
      setShowChips(selectedFilters.updatedShowChips);
      const updatedFilters = {
        ...selectedFilters.selectedValues,
        Judge: selectedFilters?.selectedValues?.['Judge']?.original,
        Court: revertCourtName(selectedFilters?.selectedValues?.['Court']),
      };
      dispatch(applyFilters(searchKeyword, updatedFilters));
    }
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        backgroundColor: '#f5f5f5',
        flex: 1,
        minWidth: '278px',
        height: 'calc(100vh - 50px)',
        p: '12px 8px 0 12px',
        overflowY: 'auto',
        '::-webkit-scrollbar': {
          width: '5px',
        },
        '::-webkit-scrollbar-track': {
          background: '#fff',
        },
        '::-webkit-scrollbar-thumb': {
          background: '#bbb',
          borderRadius: '6px',
        },
      }}
    >
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          boxSizing: 'border-box',
          flexDirection: 'column',
          bgcolor: '#fff',
          p: '8px 5px 8px 8px',
          borderRadius: '8px',
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            mb: '18px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            borderBottom: '1px solid #e6e6e6',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
            }}
          >
            Filters
          </Typography>
        </Box>
        <ToggleButtonGroup
          exclusive
          size="small"
          value={selectedFilterMode}
          onChange={handleChangeFilterMode}
          sx={{
            width: '95%',
            display: 'flex',
            borderRadius: '8px',
            marginLeft: '5px',
            marginBottom:
              selectedValues &&
              Object.keys(selectedValues)?.length > 0 &&
              Object.values(selectedValues)?.some((value) => value)
                ? '4px'
                : '18px',
          }}
        >
          <ToggleButton
            size="small"
            value="standard"
            sx={{
              width: '50%',
              textTransform: 'none',
              padding: '8px 24px',
              borderRadius: '8px',
              border: '1px solid rgba(0, 0, 0, 0.1)',
              backgroundColor:
                selectedFilterMode === 'standard' ? '#00509e' : 'transparent',
              color: selectedFilterMode === 'standard' ? '#fff' : 'inherit',
              fontWeight: selectedFilterMode === 'standard' ? 'bold' : 'normal',
              '&:hover': {
                backgroundColor:
                  selectedFilterMode === 'standard' ? '#00509e' : '#e3f2fd',
              },
            }}
          >
            Standard
          </ToggleButton>
          <ToggleButton
            size="small"
            value="citation"
            sx={{
              width: '50%',
              textTransform: 'none',
              padding: '8px 24px',
              borderRadius: '8px',
              border: '1px solid rgba(0, 0, 0, 0.1)',
              backgroundColor:
                selectedFilterMode === 'citation' ? '#00509e' : 'transparent',
              color: selectedFilterMode === 'citation' ? '#fff' : 'inherit',
              fontWeight: selectedFilterMode === 'citation' ? 'bold' : 'normal',
              '&:hover': {
                backgroundColor:
                  selectedFilterMode === 'citation' ? '#00509e' : '#e3f2fd',
              },
            }}
          >
            Citation
          </ToggleButton>
        </ToggleButtonGroup>
        {selectedValues &&
          isFilterApplied &&
          Object.keys(selectedValues)?.length > 0 &&
          Object.values(selectedValues)?.some((value) => value) && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                size="small"
                color="error"
                sx={{ textTransform: 'none' }}
                onClick={handleClearFilters}
              >
                Clear filter(s)
              </Button>
            </Box>
          )}

        <Facet
          facetsData={facetValues}
          handleChange={handleChange}
          selectedValues={selectedValues}
          showChips={showChips}
          handleClearChip={handleClearChip}
          filterMode={selectedFilterMode}
        />

        {selectedFilterMode === 'citation' && (
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              gap: '6px',
              mt: '16px',
              mb: '8px',
            }}
          >
            <Button
              variant="contained"
              size="small"
              startIcon={<FilterAltIcon />}
              disabled={
                selectedValues &&
                (Object.keys(selectedValues)?.length !== 3 ||
                  Object.values(selectedValues)?.some(
                    (value) => value === null || value === ''
                  ))
              }
              sx={{
                mb: '8px',
                textTransform: 'none',
                backgroundColor: '#00509e',
                '&:hover': {
                  backgroundColor: '#003366',
                },
                '&.Mui-disabled': {
                  backgroundColor: '#336ba3',
                  color: '#fff',
                },
              }}
              onClick={handleApplyCitationFilter}
            >
              Apply
            </Button>
            <Button
              variant="contained"
              size="small"
              color="error"
              disabled={
                selectedValues &&
                (Object.keys(selectedValues)?.length === 0 ||
                  Object.values(selectedValues)?.every((value) => !value))
              }
              startIcon={<CancelIcon />}
              sx={{
                mb: '8px',
                textTransform: 'none',
                '&.Mui-disabled': {
                  backgroundColor: '#d65f5f',
                  color: '#ffffff',
                },
              }}
              onClick={handleClearFilters}
            >
              Clear
            </Button>
          </Stack>
        )}
      </Box>
    </Box>
  );
};

export default LegalSearchSidebar;
