import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const StatutePdfViewer = () => {
  const navigate = useNavigate();
  const statutePdfPreviewUrl = useSelector(
    (state) => state.statutePdfPreviewUrl
  );
  const { pdfUrl } = statutePdfPreviewUrl;
  return (
    <Box
      sx={{
        display: 'flex',
        boxSizing: 'border-box',
        flexDirection: 'column',
        height: 'calc(100vh - 50px)',
        flex: 5,
        //p: 2,
        paddingBottom: '4px',
        backgroundColor: '#f5f5f5',
      }}
    >
      {/* <Button
        sx={{
          width: '40%',
          textTransform: 'none',
          m: '12px 16px',
          color: '#303f9f',
          fontWeight: '500',
          fontSize: '0.875rem',
          padding: '8px 12px',
          backgroundColor: '#f5f5f5',
          borderRadius: '6px',
          '&:hover': {
            backgroundColor: '#e6e6e6',
          },
        }}
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate('/app/statute-search')}
      >
        Back
      </Button> */}
      <iframe
        src={pdfUrl}
        title="PDF Viewer"
        width="100%"
        height="100%"
        style={{ border: 'none' }}
      />
    </Box>
  );
};

export default StatutePdfViewer;
