import axios from 'axios';
import { googleLogout } from '@react-oauth/google';
import config from './../config/api';

export const login = (username, password) => async (dispatch) => {
  try {
    dispatch({
      type: 'USER_LOGIN_REQUEST',
    });

    const headerConfig = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const authFunctionUrl = `${config.backendUrl}/lawgptuserAuth?username=${username}&password=${password}`;
    const { data } = await axios.get(authFunctionUrl, headerConfig);
    localStorage.setItem('userInfo', JSON.stringify(data));
    dispatch({
      type: 'USER_LOGIN_SUCCESS',
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: 'USER_LOGIN_FAIL',
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const logout = () => async (dispatch) => {
  localStorage.removeItem('userInfo');
  googleLogout();
  dispatch({ type: 'USER_LOGOUT' });
  // window.location.replace("/");
};

export const signUp =
  (
    firstName,
    lastName,
    email,
    phoneNumber,
    password,
    cnic,
    userType,
    organization,
    designation,
    officeAddress,
    barCouncil
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: 'USER_SIGNUP_REQUEST',
      });

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const signUpUrl = `https://rg-law-gpt-be-auth2.azurewebsites.net/api/lawgptuserSignUp`;
      const { data } = await axios.post(
        signUpUrl,
        {
          firstName,
          lastName,
          email,
          phoneNumber,
          password,
          cnic,
          userType,
          organization,
          designation,
          officeAddress,
          barCouncil,
        },
        config
      );
      localStorage.setItem('userInfo', JSON.stringify(data));
      dispatch({
        type: 'USER_SIGNUP_SUCCESS',
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: 'USER_SIGNUP_FAIL',
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
