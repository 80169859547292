export const searchResultsCountReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SEARCH_RESULTS_COUNT_REQUEST':
      return { loading: true };
    case 'SEARCH_RESULTS_COUNT_SUCCESS':
      return { loading: false, count: action.payload };
    case 'SEARCH_RESULTS_COUNT_FAIL':
      return { loading: false, error: action.payload };
    case 'SEARCH_RESULTS_COUNT_RESET':
      return {};
    default:
      return state;
  }
};

export const searchResultsReducer = (state = {}, action) => {
  switch (action.type) {
    case 'GET_SEARCH_RESULTS_REQUEST':
      return { loading: true };
    case 'GET_SEARCH_RESULTS_SUCCESS':
      return { loading: false, results: action.payload };
    case 'GET_SEARCH_RESULTS_FAIL':
      return { loading: false, error: action.payload };
    case 'GET_SEARCH_RESULTS_RESET':
      return {};
    default:
      return state;
  }
};

export const searchFacetsReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SEARCH_FACETS_REQUEST':
      return { loading: true };
    case 'SEARCH_FACETS_SUCCESS':
      return { loading: false, facets: action.payload };
    case 'SEARCH_FACETS_FAIL':
      return { loading: false, error: action.payload };
    case 'SEARCH_FACETS_RESET':
      return {};
    default:
      return state;
  }
};

export const searchFilterPaginationReducer = (
  state = { pageNumber: 1, resultsCount: 10 },
  action
) => {
  switch (action.type) {
    case 'SEARCH_FILTER_PAGINATION_SET':
      return { pagination: action.payload };
    case 'SEARCH_FILTER_PAGINATION_RESET':
      return {};
    default:
      return state;
  }
};

export const searchFilterValuesReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SEARCH_FILTER_VALUES_REQUEST':
      return { loading: true };
    case 'SEARCH_FILTER_VALUES_SUCCESS':
      return { loading: false, filters: action.payload };
    case 'SEARCH_FILTER_VALUES_FAIL':
      return { loading: false, error: action.payload };
    case 'SEARCH_FILTER_VALUES_RESET':
      return {};
    default:
      return state;
  }
};

export const searchSelectedFiltersReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SEARCH_SELECTED_FILTERS_SUCCESS':
      return { loading: false, selectedFilters: action.payload };
    case 'SEARCH_SELECTED_FILTERS_RESET':
      return {};
    default:
      return state;
  }
};

export const caseDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case 'CASE_DETAIL_REQUEST':
      return { loading: true };
    case 'CASE_DETAIL_SUCCESS':
      return { loading: false, caseItem: action.payload };
    case 'CASE_DETAIL_FAIL':
      return { loading: false, error: action.payload };
    case 'CASE_DETAIL_RESET':
      return {};
    default:
      return state;
  }
};

export const caseDetailsFromCosmosReducer = (state = {}, action) => {
  switch (action.type) {
    case 'CASE_DETAILS_FROM_COSMOS_REQUEST':
      return { loading: true };
    case 'CASE_DETAILS_FROM_COSMOS_SUCCESS':
      return { loading: false, caseItem: action.payload };
    case 'CASE_DETAILS_FROM_COSMOS_FAIL':
      return { loading: false, error: action.payload };
    case 'CASE_DETAILS_FROM_COSMOS_RESET':
      return {};
    default:
      return state;
  }
};

export const caseFiltersReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_CASE_FILTERS':
      return { filters: action.payload };
    case 'RESET_CASE_FILTERS':
      return {};
    default:
      return state;
  }
};
