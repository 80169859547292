import { Typography } from '@mui/material';
import { Box } from '@mui/system';

// List of prepositions to keep lowercase
const prepositions = [
  'to',
  'of',
  'through',
  'in',
  'on',
  'at',
  'with',
  'by',
  'for',
  'about',
  'against',
  'between',
  'into',
  'during',
  'without',
  'before',
  'under',
  'around',
  'among',
];

const isRomanNumeral = (word) => {
  const romanRegex =
    /^(?=[MDCLXVI])M*(C[MD]|D?C{0,3})(X[CL]|L?X{0,3})(I[XV]|V?I{0,3})$/i;
  return romanRegex.test(word);
};

export const formatRefLabel = (input) => {
  const formattedParts = input.split(',').map((part) =>
    part
      .trim()
      .split(' ')
      .map((word, index) => {
        const lowerCaseWord = word.toLowerCase();
        if (
          index !== 0 &&
          prepositions.includes(lowerCaseWord) &&
          !isRomanNumeral(word)
        ) {
          return lowerCaseWord;
        }
        return isRomanNumeral(word)
          ? word.toUpperCase()
          : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(' ')
  );

  const uniqueParts = [...new Set(formattedParts)];

  return uniqueParts.join(', ');
};

export const wait = () => {
  return new Promise((resolve) => {
    setTimeout(resolve, 2000);
  });
};

export const formatTitle = (title) => {
  if (!title) return;
  const titleCaseTitle = title
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
  return titleCaseTitle;
};

export const renderMarkdown = (markdown) => {
  const lines = markdown.split('\n');

  return lines.map((line, index) => {
    // Handle headings
    if (line.startsWith('### ')) {
      return (
        <Typography variant="h6" key={index}>
          {line.replace('### ', '')}
        </Typography>
      );
    }
    if (line.startsWith('## ')) {
      return (
        <Typography variant="h5" key={index}>
          {line.replace('## ', '')}
        </Typography>
      );
    }
    if (line.startsWith('# ')) {
      return (
        <Typography variant="h4" key={index}>
          {line.replace('# ', '')}
        </Typography>
      );
    }

    // Handle numbered lists (e.g., 1., 2., 3.)
    const numberedListMatch = line.match(/^(\d+)\. /);
    if (numberedListMatch) {
      const cleanLine = line.replace(/^\d+\. /, ''); // Remove leading `1.`
      const parts = cleanLine.split(/\*\*(.+?)\*\*/); // Split bold text

      return (
        <Box key={index}>
          <Typography variant="body1" component="div">
            {numberedListMatch[1]}.{' '}
            {parts.map((part, i) =>
              i % 2 === 1 ? (
                <Typography component="span" fontWeight="bold" key={i}>
                  {part}
                </Typography>
              ) : (
                part
              )
            )}
          </Typography>
        </Box>
      );
    }

    // Handle bullet points within numbered lists
    if (line.startsWith('- ')) {
      const cleanLine = line.replace('- ', '• '); // Replace `-` with bullet
      const parts = cleanLine.split(/\*\*(.+?)\*\*/); // Split bold text

      return (
        <Box key={index} sx={{ paddingLeft: '20px' }}>
          {' '}
          {/* Indented for bullets */}
          <Typography
            variant="body1"
            component="div"
            sx={{ textAlign: 'justify' }}
          >
            {parts.map((part, i) =>
              i % 2 === 1 ? (
                <Typography component="span" fontWeight="bold" key={i}>
                  {part}
                </Typography>
              ) : (
                part
              )
            )}
          </Typography>
        </Box>
      );
    }

    // Handle bold text for non-list items
    if (/\*\*(.+?)\*\*/.test(line)) {
      const parts = line.split(/\*\*(.+?)\*\*/);
      return (
        <Typography variant="body1" paragraph key={index}>
          {parts.map((part, i) =>
            i % 2 === 1 ? (
              <Typography component="span" fontWeight="bold" key={i}>
                {part}
              </Typography>
            ) : (
              part
            )
          )}
        </Typography>
      );
    }

    // Handle regular paragraphs
    return (
      <Typography variant="body1" marginBottom="8px" key={index}>
        {line}
      </Typography>
    );
  });
};
