import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import PlagiarismOutlinedIcon from '@mui/icons-material/PlagiarismOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';

const ChatModeBox = ({ text, isSelected, onClick }) => {
  const theme = useTheme();
  const location = useLocation();
  return (
    <Box
      onClick={onClick}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'start',
        backgroundColor: '#f5f5f5',
        borderRadius: '8px',
        width: '100%',
        height: 'auto',
        padding: '16px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        cursor: 'pointer',
        border: isSelected ? `2px solid ${theme.palette.primary.main}` : 'none',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
        },
        '&:active': {
          transform: 'translateY(0)',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        },
        '& img': {
          marginBottom: '12px',
        },
      }}
    >
      {text === 'Hybrid Chat' && (
        <AccountTreeOutlinedIcon
          sx={{ mt: 1, color: '#3c50b1' }}
          fontSize="large"
        />
      )}
      {text === 'Statute Chat' && (
        <MenuBookOutlinedIcon
          sx={{ mt: 1, color: '#3c50b1' }}
          fontSize="large"
        />
      )}
      {text === 'Case Chat' && (
        <PlagiarismOutlinedIcon
          sx={{ mt: 1, color: '#3c50b1' }}
          fontSize="large"
        />
      )}

      <Typography
        sx={{ fontSize: '17px', color: '#36454f', fontWeight: 'bold' }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default ChatModeBox;
