import React from 'react';
import {
  Box,
  List,
  ListItemText,
  Typography,
  ListItemButton,
  Button,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const CaseSummarySidebar = ({ onSectionClick, selectedSection }) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: '278px',
        overflowY: 'auto',
        height: 'calc(100vh - 50px)',
        boxSizing: 'border-box',
        bgcolor: '#f5f5f5',
        p: '8px 4px 8px 12px',
        '::-webkit-scrollbar': {
          width: '5px',
        },
        '::-webkit-scrollbar-track': {
          background: '#f1f1f1',
        },
        '::-webkit-scrollbar-thumb': {
          background: '#101936',
          borderRadius: '6px',
        },
      }}
    >
      <Box
        sx={{
          bgcolor: 'white',
          borderRadius: '8px',
          width: '100%',
          height: '100%',
        }}
      >
        <Button
          sx={{
            textTransform: 'none',
            m: '8px 4px',
            color: '#303f9f',
            fontWeight: 'bold',
          }}
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/app/searchResults')}
        >
          Back
        </Button>
        <Typography
          variant="h6"
          sx={{
            padding: '4px 16px 10px 16px',
            borderBottom: '1px solid #e6e6e6',
          }}
        >
          Navigation
        </Typography>
        <List sx={{ paddingTop: 0 }}>
          <ListItemButton
            sx={{
              pl: 2,
              pt: '4px',
              pb: '4px',
              backgroundColor:
                selectedSection === 'case-information' ? '#f5f5f5' : '',
              borderLeft:
                selectedSection === 'case-information'
                  ? '2px solid #303f9f'
                  : '',
            }}
            onClick={() => onSectionClick('case-information')}
          >
            <ListItemText primary="Case Information" />
          </ListItemButton>
          <ListItemButton
            sx={{
              pl: 2,
              pt: '4px',
              pb: '4px',
              backgroundColor:
                selectedSection === 'legal-provisions' ? '#f5f5f5' : '',
              borderLeft:
                selectedSection === 'legal-provisions'
                  ? '2px solid #303f9f'
                  : '',
            }}
            onClick={() => onSectionClick('legal-provisions')}
          >
            <ListItemText primary="Legal Provisions" />
          </ListItemButton>
          <ListItemButton
            sx={{
              pl: 2,
              pt: '4px',
              pb: '4px',
              backgroundColor:
                selectedSection === 'case-summary' ? '#f5f5f5' : '',
              borderLeft:
                selectedSection === 'case-summary' ? '2px solid #303f9f' : '',
            }}
            onClick={() => onSectionClick('case-summary')}
          >
            <ListItemText primary="Summary" />
          </ListItemButton>
          <ListItemButton
            sx={{
              pl: 2,
              pt: '4px',
              pb: '4px',
              backgroundColor:
                selectedSection === `courts-findings` ? '#f5f5f5' : '',
              borderLeft:
                selectedSection === 'courts-findings'
                  ? '2px solid #303f9f'
                  : '',
            }}
            onClick={() => onSectionClick(`courts-findings`)}
          >
            <ListItemText primary="Court's Findings" />
          </ListItemButton>
          <ListItemButton
            sx={{
              pl: 2,
              pt: '4px',
              pb: '4px',
              backgroundColor:
                selectedSection === 'case-law-references' ? '#f5f5f5' : '',
              borderLeft:
                selectedSection === 'case-law-references'
                  ? '2px solid #303f9f'
                  : '',
            }}
            onClick={() => onSectionClick('case-law-references')}
          >
            <ListItemText primary="Case Law References" />
          </ListItemButton>
        </List>
      </Box>
    </Box>
  );
};

export default CaseSummarySidebar;
