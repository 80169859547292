import config from "../config/api";

export const getApplicationHistory = (userId, chatId) => async (dispatch) => {
  try {
    dispatch({
      type: "APPLICATION_HISTORY_REQUEST",
    });
    const endpoint = `${config.petitionUrl}/get_application`;
    const data = { user_id: userId, chat_id: chatId };
    const response = await fetch(endpoint, {
      method: "POST",
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const responseData = await response.json();
      dispatch({
        type: "APPLICATION_HISTORY_SUCCESS",
        payload: { ...responseData, chatId },
      });
    } else {
      console.log("Chat history not found");
    }
  } catch (error) {
    dispatch({
      type: "APPLICATION_HISTORY_FAIL",
      payload: error.response?.data?.message ?? error.message,
    });
  }
};

export const editApplicationTitle =
  (userId, chatId, newTitle) => async (dispatch) => {
    try {
      dispatch({
        type: "EDIT_APPLICATION_TITLE_REQUEST",
      });
      const endpoint = `${config.petitionUrl}/edit_application_title`;
      const response = await fetch(endpoint, {
        method: "POST",
        body: JSON.stringify({
          user_id: userId,
          chat_id: chatId,
          chat_title: newTitle,
        }),
      });
      if (response.ok) {
        const responseData = await response.text();
        console.log(responseData);
        dispatch({
          type: "EDIT_APPLICATION_TITLE_SUCCESS",
          payload: responseData.message,
        });
        console.log("Title updated");
      } else {
        console.log("Title not found");
      }
    } catch (error) {
      dispatch({
        type: "EDIT_APPLICATION_TITLE_FAIL",
        payload: error.response?.data?.message ?? error.message,
      });
    }
  };

export const deleteApplication = (userId, chatId) => async (dispatch) => {
  try {
    dispatch({
      type: "DELETE_APPLICATION_REQUEST",
    });
    const endpoint = `${config.petitionUrl}/DeleteApplication`;
    const response = await fetch(endpoint, {
      method: "POST",
      body: JSON.stringify({ user_id: userId, chat_id: chatId }),
    });
    if (response.ok) {
      const responseData = await response.text();
      dispatch({
        type: "DELETE_APPLICATION_SUCCESS",
        payload: responseData.message,
      });
      console.log("Chat deleted");
    } else {
      console.log("Chat not found");
    }
  } catch (error) {
    dispatch({
      type: "DELETE_APPLICATION_FAIL",
      payload: error.response?.data?.message ?? error.message,
    });
  }
};

export const getApplicationTitles = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_APPLICATION_TITLES_REQUEST",
    });
    const endpoint = `${config.petitionUrl}/HistoryApplication`;

    const response = await fetch(endpoint, {
      method: "POST",
      body: JSON.stringify({ user_id: userId }),
    });
    if (response.ok) {
      const responseData = await response.json();
      dispatch({
        type: "GET_APPLICATION_TITLES_SUCCESS",
        payload: responseData,
      });
    }
  } catch (error) {
    dispatch({
      type: "GET_APPLICATION_TITLES_FAIL",
      payload:
        "Application history could not be fetched at this moment. Please try again later.",
    });
  }
};
