import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Typography,
  TextField,
  CircularProgress,
  IconButton,
} from '@mui/material';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { ExpandMore } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';

const CaseSummary = ({ selectedSection }) => {
  const caseDetailsFromCosmos = useSelector(
    (state) => state.caseDetailsFromCosmos
  );
  const { loading: loadingAdditionalDetails, caseItem: additionalCaseDetails } =
    caseDetailsFromCosmos;

  const [searchTerm, setSearchTerm] = useState('');
  const [currentMatchIndex, setCurrentMatchIndex] = useState(0);
  const [highlightedElements, setHighlightedElements] = useState([]);
  const markRefs = useRef([]);

  const generateId = (text) => {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^a-z0-9-]/g, '');
  };

  const handleNextMatch = () => {
    if (highlightedElements.length === 0) return;

    // Remove "active" class from the previous element
    const prevElement = document.getElementById(`match-${currentMatchIndex}`);
    if (prevElement) {
      prevElement.classList.remove('active-match');
    }

    const nextIndex = (currentMatchIndex + 1) % highlightedElements.length;
    const nextElement = document.getElementById(`match-${nextIndex}`);

    if (nextElement) {
      nextElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      nextElement.classList.add('active-match');
      setCurrentMatchIndex(nextIndex);
    }
  };

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  const highlightedContent = useMemo(() => {
    if (!searchTerm) {
      setHighlightedElements([]);
      return additionalCaseDetails?.generatedHeadNotes;
    }

    const keywordPattern = new RegExp(`(${escapeRegExp(searchTerm)})`, 'gi');
    let matchIndex = 0;

    // Split content by the pattern without altering Markdown structure
    const contentWithHighlights =
      additionalCaseDetails?.generatedHeadNotes.split(keywordPattern);

    // Reassemble content with <mark> tags and unique IDs for each match
    const highlighted = contentWithHighlights
      .map((part, index) => {
        if (keywordPattern.test(part)) {
          return `<mark id="match-${matchIndex++}">${part}</mark>`;
        }
        return part;
      })
      .join('');

    // Store match IDs in state to enable scrolling between them
    setHighlightedElements([...Array(matchIndex).keys()]);

    return highlighted;
  }, [additionalCaseDetails?.generatedHeadNotes, searchTerm]);

  useEffect(() => {
    let previousSection = null;
    if (
      selectedSection === 'case-information' ||
      selectedSection === 'legal-provisions' ||
      selectedSection === 'case-summary' ||
      selectedSection === 'courts-findings' ||
      selectedSection === 'case-law-references'
    ) {
      const section = document.getElementById(selectedSection);

      if (previousSection) {
        previousSection.style.backgroundColor = '';
      }
      if (section) {
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        section.style.backgroundColor = 'yellow';
        previousSection = section;
      }
    }

    return () => {
      if (previousSection) {
        previousSection.style.backgroundColor = '';
      }
    };
  }, [selectedSection]);

  useEffect(() => {
    if (currentMatchIndex !== -1 && markRefs.current[currentMatchIndex]) {
      markRefs.current[currentMatchIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [currentMatchIndex]);

  useEffect(() => {
    setCurrentMatchIndex(0);
  }, [searchTerm]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 50px)',
        flex: 5,
        flexGrow: 1,
        boxSizing: 'border-box',
        bgcolor: '#f5f5f5',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          bgcolor: '#f5f5f5',
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{
            backgroundColor: 'white',
            margin: '8px 8px 8px 8px',
            borderRadius: '24px',
            '& .MuiInputBase-root': {
              height: '40px',
              fontSize: '14px',
            },
            '& .MuiOutlinedInput-root': {
              borderRadius: '24px',
            },
          }}
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
        />
        <IconButton
          sx={{ mr: 2 }}
          onClick={handleNextMatch}
          disabled={highlightedElements.length === 0}
        >
          <ExpandMore />
        </IconButton>
      </Box>
      {(selectedSection === 'Case Summary' ||
        selectedSection === 'case-information' ||
        selectedSection === 'legal-provisions' ||
        selectedSection === 'case-summary' ||
        selectedSection === `courts-findings` ||
        selectedSection === 'case-law-references') && (
        <React.Fragment>
          {loadingAdditionalDetails ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '24px',
                width: '100%',
              }}
            >
              <CircularProgress
                size={20}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                boxSizing: 'border-box',
                flexDirection: 'column',
                height: '100%',
                overflowY: 'auto',
                bgcolor: '#ffffff',
                p: '8px 12px',
                m: 1,
                borderRadius: '8px',
                '::-webkit-scrollbar': {
                  width: '5px',
                },
                '::-webkit-scrollbar-track': {
                  background: '#f1f1f1',
                },
                '::-webkit-scrollbar-thumb': {
                  background: '#101936',
                  borderRadius: '6px',
                },
              }}
            >
              <Typography sx={{ textAlign: 'justify' }}>
                <Markdown
                  rehypePlugins={[rehypeRaw]}
                  components={{
                    h1: ({ node, ...props }) => {
                      const headingText = props.children;
                      const headingId = generateId(headingText);
                      return (
                        <Typography
                          id={headingId}
                          variant="h6"
                          sx={{
                            fontWeight: 'bold',
                            color: '#303f9f',
                            borderBottom: '1px solid #e6e6e6',
                            paddingBottom: '8px',
                            marginBottom: '12px',
                            marginTop: '8px',
                          }}
                          {...props}
                        />
                      );
                    },
                    h3: ({ node, ...props }) => (
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: 'bold',
                          marginBottom: '4px',
                        }}
                        {...props}
                      />
                    ),
                    h4: ({ node, ...props }) => (
                      <Typography
                        variant="subtitle2"
                        sx={{
                          fontWeight: 'bold',
                          marginBottom: '6px',
                          marginTop: '6px',
                        }}
                        {...props}
                      />
                    ),
                    p: ({ node, ...props }) => (
                      <Typography
                        sx={{
                          fontSize: '14px',
                          marginBottom: '6px',
                        }}
                        {...props}
                      />
                    ),
                    ol: ({ node, ...props }) => (
                      <Box
                        component="ol"
                        sx={{
                          fontSize: '14px',
                          marginBottom: '6px',
                          paddingLeft: '20px',
                          listStyleType: 'decimal',
                        }}
                        {...props}
                      />
                    ),
                    ul: ({ node, ...props }) => (
                      <Box
                        component="ul"
                        sx={{
                          fontSize: '14px',
                          marginBottom: '6px',
                          paddingLeft: '20px',
                          listStyleType: 'disc',
                        }}
                        {...props}
                      />
                    ),
                    li: ({ node, ...props }) => (
                      <Typography
                        component="li"
                        sx={{
                          fontSize: '14px',
                          marginBottom: '4px',
                        }}
                        {...props}
                      />
                    ),
                    mark: ({ node, ...props }) => (
                      <Typography
                        component="mark"
                        sx={{
                          backgroundColor:
                            props.id === `match-${currentMatchIndex}`
                              ? '#ff9800'
                              : '#ffeb3b',
                          padding: '0 2px',
                          fontSize: 'inherit',
                          fontWeight: 'inherit',
                        }}
                        {...props}
                      />
                    ),
                  }}
                >
                  {highlightedContent}
                </Markdown>
              </Typography>
            </Box>
          )}
        </React.Fragment>
      )}
    </Box>
  );
};

export default CaseSummary;
