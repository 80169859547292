import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Typography,
  TextField,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';

const StatuteDetail = ({ selectedSection }) => {
  const statuteSections = useSelector((state) => state.statuteSections);
  const { loading, sections } = statuteSections;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [searchTerm, setSearchTerm] = useState('');
  const [highlightedElements, setHighlightedElements] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const containerRef = useRef();

  const highlightText = (html, search, section) => {
    if ((!search && !section) || section === 'PREAMBLE. Preamble') return html;

    // Helper to escape special regex characters
    const escapeRegex = (text) => text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    // Escape search and section terms for regex
    const escapedSearch = search ? escapeRegex(search) : null;
    const escapedSection = section ? escapeRegex(section) : null;
    // ? escapeRegex(section)
    //     .replace(/\s+/g, '(\\s|<[^>]+>|\\n|\\r)*') // Match whitespace, HTML tags, and line breaks
    //     .replace(/,/g, ',?\\s*') // Make commas optional with possible whitespace
    //     .replace(/-/g, '-?') // Make hyphens optional
    // : null;

    // Compile regular expressions
    const regexSearch = escapedSearch
      ? new RegExp(
          `(${escapedSearch.replace(
            /\s+/g,
            '(\\s|<[^>]+>|\\n|\\r|&nbsp;|<\\/?.+?>)*'
          )})`,
          'gi'
        )
      : null;
    const regexSection = escapedSection
      ? new RegExp(
          `(${escapedSection.replace(
            /\s+/g,
            '(\\s|<[^>]+>|\\n|\\r|&nbsp;|<\\/?.+?>)*'
          )})`,
          'gi'
        )
      : null;

    // Helper function for applying highlights
    const applyHighlight = (text, regex, className) => {
      return text.replace(
        regex,
        `<span class="${className}" style="background-color: ${
          className === 'highlight' ? 'yellow' : 'lightgreen'
        }; font-weight: bold;">$1</span>`
      );
    };

    // Recursive function to apply highlights in text nodes
    const highlightNode = (node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        let highlightedText = node.textContent;
        if (regexSearch) {
          highlightedText = applyHighlight(
            highlightedText,
            regexSearch,
            'highlight'
          );
        }
        if (regexSection) {
          highlightedText = applyHighlight(
            highlightedText,
            regexSection,
            'sidebar-highlight'
          );
        }
        return highlightedText;
      }

      // If it's an element, recursively process its children
      if (node.nodeType === Node.ELEMENT_NODE) {
        const children = Array.from(node.childNodes)
          .map(highlightNode)
          .join('');
        const element = document.createElement(node.nodeName.toLowerCase());
        Array.from(node.attributes).forEach((attr) =>
          element.setAttribute(attr.name, attr.value)
        );
        element.innerHTML = children;
        return element.outerHTML;
      }
      return '';
    };

    // Parse the HTML string, apply highlights, and return the final HTML
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    return Array.from(tempDiv.childNodes).map(highlightNode).join('');
  };

  const handleNextMatch = () => {
    if (highlightedElements.length > 0) {
      const nextIndex = (currentIndex + 1) % highlightedElements.length;
      setCurrentIndex(nextIndex);
    }
  };

  useEffect(() => {
    if (!searchTerm) {
      setCurrentIndex(-1);
      setHighlightedElements([]);
    }
  }, [searchTerm]);

  useEffect(() => {
    // Only query highlights when searchTerm changes
    const highlights = document.querySelectorAll('.highlight');
    setHighlightedElements(Array.from(highlights));
  }, [searchTerm]);

  useEffect(() => {
    if (highlightedElements.length > 0) {
      highlightedElements.forEach((el, index) => {
        if (index === currentIndex) {
          el.style.backgroundColor = 'orange';
          el.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else {
          el.style.backgroundColor = 'yellow';
        }
      });
    }
  }, [currentIndex, highlightedElements]);

  useEffect(() => {
    if (selectedSection) {
      if (
        selectedSection.includes('preamble') ||
        selectedSection.includes('Preamble')
      ) {
        containerRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        const sidebarHighlight = document.querySelector('.sidebar-highlight');
        if (sidebarHighlight) {
          sidebarHighlight.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      }
    }
  }, [selectedSection]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 50px)',
        flex: 5,
        flexGrow: 1,
        boxSizing: 'border-box',
        bgcolor: '#f5f5f5',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '98%',
          bgcolor: '#f5f5f5',
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{
            backgroundColor: 'white',
            margin: '8px 18px 8px 18px',
            borderRadius: '24px',
            '& .MuiInputBase-root': {
              height: '40px',
              fontSize: '14px',
            },
            '& .MuiOutlinedInput-root': {
              borderRadius: '24px',
            },
          }}
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
        />
        <IconButton
          onClick={handleNextMatch}
          disabled={highlightedElements.length === 0}
        >
          <ExpandMore />
        </IconButton>
      </Box>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '24px',
            width: '100%',
          }}
        >
          <CircularProgress
            size={20}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
        </Box>
      ) : (
        <Box
          ref={containerRef}
          sx={{
            display: 'flex',
            boxSizing: 'border-box',
            flexDirection: 'column',
            height: '100%',
            overflowY: 'auto',
            bgcolor: '#f5f5f5',
            p: '8px 12px',
            m: 1,
            '::-webkit-scrollbar': {
              width: '5px',
            },
            '::-webkit-scrollbar-track': {
              background: '#f1f1f1',
            },
            '::-webkit-scrollbar-thumb': {
              background: '#bbb',
              borderRadius: '6px',
            },
          }}
        >
          {sections?.[0]?.related_data
            ?.sort((a, b) => {
              if (a.Section === 'PREAMBLE') return -1;
              if (b.Section === 'PREAMBLE') return 1;
              const sectionA = parseFloat(a.Section);
              const sectionB = parseFloat(b.Section);
              return sectionA - sectionB;
            })
            .map((result, index) => (
              <Box
                sx={{
                  pl: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  paddingTop: 1,
                  mb: 2,
                  bgcolor: '#fff',
                  borderRadius: '8px',
                }}
              >
                <Typography
                  sx={{ p: 1 }}
                  variant="body2"
                  dangerouslySetInnerHTML={{
                    __html: highlightText(
                      result.Statute_HTML,
                      searchTerm,
                      selectedSection
                    ),
                  }}
                />
              </Box>
            ))}
        </Box>
      )}
    </Box>
  );
};

export default StatuteDetail;
