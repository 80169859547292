import { Box } from '@mui/material';
import React from 'react';
import HomeSidebar from '../components/layout/HomeSidebar';
import HomeMainPage from './HomeMainPage';
import Header from '../components/layout/Header';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const HomePage = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  return userInfo ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Header />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <HomeSidebar />
        <HomeMainPage />
      </Box>
    </Box>
  ) : (
    <Navigate to="/" />
  );
};

export default HomePage;
