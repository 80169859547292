import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dialog, DialogContent, Box, Typography } from '@mui/material';

const PetitionPreview = ({ open, onClose, content }) => {
  const cleanQuillOutput = (html) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    div.querySelectorAll('.ql-align-center, .ql-align-right').forEach((el) => {
      if (el.classList.contains('ql-align-center')) {
        el.style.textAlign = 'center';
      }
      if (el.classList.contains('ql-align-right')) {
        el.style.textAlign = 'right';
      }
    });
    return div.innerHTML;
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        {!content ? (
          <Typography>No data found</Typography>
        ) : (
          <Box
            sx={{ padding: 2 }}
            dangerouslySetInnerHTML={{
              __html: cleanQuillOutput(content),
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PetitionPreview;
