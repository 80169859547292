import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Box,
  IconButton,
  TextField,
  Radio,
  Typography,
  Stack,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { searchResults } from "../actions/searchActions";
import { statuteSearchResults } from "../actions/statuteSearchActions";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import { CasesOutlined } from "@mui/icons-material";

const LegalSearchHomePage = ({ updateSearchBar }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedMode, setSelectedMode] = useState("caseLaw");
  const [userQuery, setUserQuery] = useState("");

  const handleButtonClick = (value) => {
    setSelectedMode(value);
  };

  const handleSearch = () => {
    if (!userQuery) {
      setUserQuery("*");
    }
    updateSearchBar(selectedMode, userQuery);
    if (selectedMode === "caseLaw") {
      dispatch(searchResults(userQuery));
      navigate(`/app/searchResults`);
    } else {
      dispatch(statuteSearchResults(userQuery));
      navigate(`/app/statute-search`);
    }
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleChange = (e) => {
    setUserQuery(e.target.value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100vw",
        minHeight: "89vh",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        p: 2,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center", mb: "24px" }}>
        <img
          src="/images/legalSearchLogo2.png"
          alt="product logo"
          style={{
            maxWidth: "180px",
            height: "auto",
            objectFit: "contain",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "60%",
          bgcolor: "#fff",
          borderRadius: "12px",
        }}
      >
        <TextField
          variant="outlined"
          autoFocus
          placeholder="What are you looking for?"
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
              paddingRight: "0px",
            },
          }}
          InputProps={{
            endAdornment: (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  bgcolor: "#303f9f",
                  width: "7%",
                  height: "56px",
                  borderEndEndRadius: "12px",
                  borderTopRightRadius: "12px",
                }}
              >
                <IconButton onClick={handleSearch}>
                  <SearchIcon sx={{ color: "#fff" }} />
                </IconButton>
              </Box>
            ),
          }}
          onKeyDown={handleEnter}
          onChange={handleChange}
          value={userQuery}
        />
      </Box>
      <Box sx={{ display: "flex", marginTop: "24px", gap: 1.5 }}>
        <Stack sx={{ display: "flex", flexDirection: "row" }}>
          <Radio
            checked={selectedMode === "caseLaw"}
            onClick={() => handleButtonClick("caseLaw")}
          />
          <CasesOutlined sx={{ mt: 1, color: "#303f9f" }} />
          <Typography sx={{ mt: 1.3, color: "#303f9f", ml: 0.5 }}>
            Case Laws
          </Typography>
        </Stack>
        <Stack sx={{ display: "flex", flexDirection: "row" }}>
          <Radio
            checked={selectedMode === "statutes"}
            onClick={() => handleButtonClick("statutes")}
          />
          <MenuBookOutlinedIcon sx={{ mt: 1, color: "#303f9f" }} />
          <Typography sx={{ mt: 1.3, color: "#303f9f", ml: 0.5 }}>
            Statutes
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};

export default LegalSearchHomePage;
