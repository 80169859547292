import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Stack,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  MenuItem,
  Popover,
  Chip,
} from '@mui/material';
import { height, styled } from '@mui/system';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import LaunchIcon from '@mui/icons-material/Launch';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Close } from '@mui/icons-material';
import CaseLawPreviewModal from './CaseLawPreviewModal';

const sampleCases = [
  {
    Title:
      'RIAZ AHMED Versus State\nHonorable Justice Farooq Ali Channa\nAzher Hussain Abbasi',
    Citation_Name: '2015 PCrLJ 143',
    Section: 'PCRLJ',
    Court: 'KARACHI-HIGH-COURT-SINDH',
    Year: '2015',
  },
  {
    Title: 'ABDUL QAYYUM QURESHI Versus MANZUR AHMAD',
    Citation_Name: '1988 PCRLJ 2047',
    Section: 'PCRLJ',
    Court: 'LAHORE-HIGH-COURT-LAHORE',
    Year: '1988',
  },
  {
    Title:
      'HAFIZULLAH KHAN Versus WORKS MANAGER, MAPLE LEAF CEMENT FACTORYLIMITED, ISKANDARABAD, DISTRICT MIANWALI\nHonorable Justice Sardar Muhammad Abdul Ghafoor Khan Lodhi\nMunawar Ahmad Javed , Muhammad Azam',
    Citation_Name: '1993 PLC 990',
    Section: 'PLC',
    Court: 'KARACHI-HIGH-COURT-SINDH',
    Year: '1993',
  },
  {
    Title:
      'AIJAZ AHMAD SHEIKH Versus THE STATE\nUsman Ghani Rashid , S. Murtaza Hussain',
    Citation_Name: '1988 PCRLJ 1816',
    Section: 'PCRLJ',
    Court: 'KARACHI-HIGH-COURT-SINDH',
    Year: '1988',
  },
];

const CaseLawsSidebar = ({ onClose, cases }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    keyword: '',
    section: '',
    court: '',
    year: '',
  });
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedCase, setSelectedCase] = useState(null);
  const [filteredCases, setFilteredCases] = useState(sampleCases);
  const [yearOptions, setYearOptions] = useState([]);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [courtOptions, setCourtOptions] = useState([]);

  const handlePreviewClick = (caseItem) => {
    setSelectedCase(caseItem);
    setOpenPreview(true);
  };

  const anchorRef = useRef(null);
  const handleClickOpen = () => {
    setOpen(true);
    setAnchorEl(anchorRef.current);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const countAppliedFilters = () => {
    return Object.values(filters).filter((value) => value).length;
  };

  const handleOpenInLegalSearch = () => {
    if (isFilterApplied) {
      dispatch({ type: 'SET_CASE_FILTERS', payload: filters });
      navigate('/app/searchResults');
    }
  };

  const handleApplyFilters = () => {
    if (Object.values(filters).every((value) => value === '')) return;
    setOpen(false);
    setIsFilterApplied(true);
    // Apply filters
    const newFilteredCases = sampleCases?.filter((caseItem) => {
      return (
        (!filters.keyword ||
          caseItem.Title.toLowerCase().includes(
            filters.keyword.toLowerCase()
          )) &&
        (!filters.section ||
          caseItem.Section.toLowerCase().includes(
            filters.section.toLowerCase()
          )) &&
        (!filters.court ||
          caseItem.Court.toLowerCase().includes(
            revertCourtName(filters.court).toLowerCase()
          )) &&
        (!filters.year || caseItem.Year === filters.year)
      );
    });
    setFilteredCases(newFilteredCases);
  };

  const handleClearFilter = () => {
    setOpen(false);
    setIsFilterApplied(false);
    setFilters({
      keyword: '',
      section: '',
      court: '',
      year: '',
    });
    setFilteredCases(sampleCases);
  };

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const formatCaseTitle = (title) => {
    const splitTitle = title.split('Honorable')[0];
    const titleCaseTitle = splitTitle
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
    return titleCaseTitle;
  };

  const formatCourtName = (courtName) => {
    return courtName
      .toLowerCase()
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const revertCourtName = (formattedCourtName) => {
    return formattedCourtName?.toUpperCase().replace(/\s+/g, '-');
  };

  useEffect(() => {
    if (sampleCases) {
      // Collect unique options
      const years = new Set();
      const sections = new Set();
      const courts = new Set();

      sampleCases.forEach((item) => {
        years.add(item.Year);
        sections.add(item.Section);
        courts.add(formatCourtName(item.Court));
      });

      setYearOptions(Array.from(years));
      setSectionOptions(Array.from(sections));
      setCourtOptions(Array.from(courts));
    }
  }, [cases]);

  return (
    <React.Fragment>
      {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
        <Stack
          sx={{ display: 'flex', flexDirection: 'row' }}
          onClick={handleOpenInLegalSearch}
        >
          <IconButton sx={{ p: 0, ml: 0.5, mr: 0.5 }}>
            <LaunchIcon fontSize="small" />
          </IconButton>
          <Typography
            variant="body2"
            sx={{
              color: '#303f9f',
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            Open in Legal Search
          </Typography>
        </Stack>
        <IconButton sx={{ p: 0, ml: 0.5, mr: 1 }} onClick={() => onClose()}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box> */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, gap: 1 }}>
        <TextField
          variant="outlined"
          size="small"
          placeholder="Select Filters"
          sx={{
            '& .MuiInputBase-root': {
              height: '33px',
            },
          }}
          InputProps={{
            readOnly: true,
            style: { borderRadius: '15px', paddingRight: '2px' },
            endAdornment: (
              <IconButton onClick={handleClickOpen}>
                <ExpandMoreIcon />
              </IconButton>
            ),
          }}
          ref={anchorRef}
        />
        <IconButton sx={{ p: 0, ml: 0.5, mr: 1 }} onClick={() => onClose()}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      {isFilterApplied && (
        <>
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              mt: 1,
              ml: 1,
            }}
          >
            <Typography variant="subtitle1">{`${countAppliedFilters()} Filters Applied`}</Typography>
            <Button
              color="error"
              size="small"
              sx={{ textTransform: 'none' }}
              onClick={handleClearFilter}
            >
              Clear Filters
            </Button>
          </Stack>
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: 1,
              mt: 1,
              ml: 2,
            }}
          >
            {Object.entries(filters).map(([key, value]) =>
              value ? (
                <Chip
                  key={key}
                  label={`${value}`}
                  sx={{ bgcolor: '#101936', color: 'white' }}
                  size="small"
                />
              ) : null
            )}{' '}
          </Stack>
        </>
      )}
      <List>
        {filteredCases?.length === 0 ? (
          <Typography variant="body1" sx={{ textAlign: 'center', mt: 2 }}>
            No cases found
          </Typography>
        ) : (
          filteredCases?.map((caseItem, index) => (
            <ListItem key={index} sx={{ alignItems: 'flex-start' }}>
              <Box
                sx={{
                  width: '100%',
                }}
              >
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: '#303f9f',
                        ml: 1,
                        cursor: 'pointer',
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      {formatCaseTitle(caseItem.Title)}
                    </Typography>
                  }
                  secondary={
                    <>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          display: 'flex',
                          bgcolor: '#f8f8fb',
                          p: '6px 8px',
                          borderRadius: '4px',
                        }}
                      >
                        {caseItem.Citation_Name}
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          mt: 1,
                          gap: 2,
                        }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <IconButton sx={{ p: 0, ml: 0.5, mr: 0.5 }}>
                            <VisibilityIcon
                              fontSize="small"
                              sx={{ color: '#2196f3' }}
                            />
                          </IconButton>
                          <Typography
                            variant="body2"
                            sx={{
                              mr: 0.5,
                              color: '#303f9f',
                              cursor: 'pointer',
                              '&:hover': {
                                textDecoration: 'underline',
                              },
                            }}
                            onClick={() => handlePreviewClick(caseItem)}
                          >
                            Preview
                          </Typography>
                          <CaseLawPreviewModal
                            open={openPreview}
                            onClose={() => setOpenPreview(false)}
                            title={
                              selectedCase
                                ? formatCaseTitle(selectedCase.Title)
                                : ''
                            }
                            caseDescriptionContent={
                              selectedCase
                                ? selectedCase.Case_Description_HTML
                                : ''
                            }
                            headNotesContent={
                              selectedCase ? selectedCase.Head_Notes_HTML : ''
                            }
                          />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <IconButton sx={{ p: 0 }}>
                            <TurnedInNotIcon
                              fontSize="small"
                              sx={{ color: '#d2042d' }}
                            />
                          </IconButton>
                          <Typography
                            variant="body2"
                            sx={{
                              ml: 0.5,
                              color: '#303f9f',
                              cursor: 'pointer',
                              '&:hover': {
                                textDecoration: 'underline',
                              },
                            }}
                          >
                            Bookmark
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  }
                />
                <Divider />
              </Box>
            </ListItem>
          ))
        )}
      </List>
      {/* Popup Dialog */}
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ mt: 6 }}
        PaperProps={{
          sx: {
            borderRadius: '8px',
          },
        }}
      >
        <DialogTitle fontSize="14px">Filter Options</DialogTitle>
        <DialogContent>
          <Box
            sx={{ display: 'flex', flexDirection: 'column', gap: 1.5, mt: 1 }}
          >
            <TextField
              size="small"
              label="Enter Keyword"
              variant="outlined"
              value={filters.keyword}
              sx={{
                marginBottom: '4px',
                '& .MuiInputBase-root': {
                  height: '33px',
                  fontSize: '13px',
                },
                '& .MuiInputLabel-root': {
                  fontSize: '13px',
                },
              }}
              onChange={(e) => handleFilterChange('keyword', e.target.value)}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                sx: { borderRadius: '15px' },
              }}
            />
            <TextField
              size="small"
              select
              label="Enter Section"
              variant="outlined"
              sx={{
                marginBottom: '4px',
                '& .MuiInputBase-root': {
                  height: '33px',
                  fontSize: '13px',
                },
                '& .MuiInputLabel-root': {
                  fontSize: '13px',
                },
              }}
              value={filters.section}
              onChange={(e) => handleFilterChange('section', e.target.value)}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                style: { borderRadius: '15px' },
              }}
            >
              {sectionOptions.map((section, index) => (
                <MenuItem key={index} value={section} sx={{ fontSize: '13px' }}>
                  {section}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              size="small"
              select
              label="Enter Court"
              variant="outlined"
              sx={{
                marginBottom: '4px',
                '& .MuiInputBase-root': {
                  height: '33px',
                  fontSize: '13px',
                },
                '& .MuiInputLabel-root': {
                  fontSize: '13px',
                },
              }}
              value={filters.court}
              onChange={(e) => handleFilterChange('court', e.target.value)}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                style: { borderRadius: '15px' },
              }}
            >
              {courtOptions.map((court, index) => (
                <MenuItem key={index} value={court} sx={{ fontSize: '13px' }}>
                  {court}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              size="small"
              label="Year"
              variant="outlined"
              select
              sx={{
                marginBottom: '4px',
                '& .MuiInputBase-root': {
                  height: '33px',
                  fontSize: '13px',
                },
                '& .MuiInputLabel-root': {
                  fontSize: '13px',
                },
              }}
              value={filters.year}
              onChange={(e) => handleFilterChange('year', e.target.value)}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                style: { borderRadius: '15px' },
              }}
            >
              {yearOptions.map((year, index) => (
                <MenuItem key={index} value={year} sx={{ fontSize: '13px' }}>
                  {year}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            variant="contained"
            color="primary"
            sx={{
              borderRadius: '15px',
              textTransform: 'none',
              backgroundColor: '#101936',
              color: '#fff',
              '&:hover': {
                backgroundColor: '#303f9f',
                color: '#fff',
              },
            }}
            onClick={handleApplyFilters}
          >
            Apply Filters
          </Button>
          <Button
            size="small"
            color="error"
            sx={{ textTransform: 'none', borderRadius: '15px' }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Popover>
    </React.Fragment>
  );
};

export default CaseLawsSidebar;
