import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Grid } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ChatIcon from '@mui/icons-material/Chat';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import GavelIcon from '@mui/icons-material/Gavel';
import { CasesOutlined } from '@mui/icons-material';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import DescriptionIcon from '@mui/icons-material/Description';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import { faWpforms } from '@fortawesome/free-brands-svg-icons';
import { Stack } from '@mui/system';

const HomeMainPage = () => {
  const navigate = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const handleCardClick = (route) => {
    if (route === '/app/case-management') return;
    if (route === '/app/legal-doc') return;
    if (route === '/app/legal-library') return;
    navigate(route);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        boxSizing: 'border-box',
        flexDirection: 'column',
        backgroundColor: '#f5f5f5',
        flex: 5,
        p: '2px 8px 0px 8px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '5%',
        }}
      >
        {/* <Box
          sx={{
            display: 'flex',
            mb: 1.5,
          }}
        >
          <img
            src="/images/legalSearchLogo2.png"
            alt="product logo"
            style={{
              maxWidth: '180px',
              height: 'auto',
              objectFit: 'contain',
            }}
          />
        </Box> */}
        <Box mb={2}>
          <Typography
            variant="h4"
            //component="span"
            sx={{
              fontWeight: 'bold',
              color: '#4285F4', // Blue for the "Hello,"
              display: 'inline-block',
            }}
          >
            Hello,
          </Typography>
          <Typography
            variant="h4"
            component="span"
            sx={{
              fontWeight: 'bold',
              background: 'linear-gradient(90deg, #6a88e2, #a56dd2, #ec6f78)', // Gradient for the name
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              display: 'inline-block',
              marginLeft: '8px',
            }}
          >
            {userInfo?.name}
          </Typography>
          <Typography
            variant="h5"
            component="div"
            sx={{
              color: '#b0b0b0',
              fontWeight: 400,
              marginTop: '4px',
            }}
          >
            Welcome to LawGPT, how can I help you today?
          </Typography>
        </Box>
        {/* <Typography variant="h6" sx={{ color: '#31429B', fontWeight: 'bold' }}>
          Welcome to Your AI Legal Assistant!
        </Typography>
        <Typography mb="12px" fontSize="17px" mt="6px">
          How can I help you today?
        </Typography> */}
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          width="43%"
        >
          {[
            {
              label: 'Legal Chat',
              text: 'Expert AI-Driven Legal Responses',
              icon: <ChatIcon sx={{ color: '#76d0eb' }} />,
              route: '/app/legal-chat',
            },
            {
              label: 'Case Law Search',
              text: 'Swift Access to Pertinent Case Laws',
              icon: <GavelIcon sx={{ color: '#a56d51' }} />,
              route: '/app/searchResults',
            },
            {
              label: 'Statute Search',
              text: 'Rapidly Find Relevant Statutes',
              icon: <MenuBookOutlinedIcon sx={{ color: '#e2c541' }} />,
              route: '/app/statute-search',
            },
            {
              label: 'Petition Management',
              text: 'Draft High-Caliber Legal Petitions',
              icon: <AssignmentIcon sx={{ color: '#00d67f' }} />,
              route: '/app/petition',
            },
            {
              label: 'Application Management',
              text: 'AI-Optimized Legal Application Creation',
              icon: (
                <DescriptionIcon sx={{ color: '#cb8bd0' }} />
                // <FontAwesomeIcon
                //   icon={faWpforms}
                //   color="#cb8bd0"
                //   fontSize="20px"
                // />
              ),
              route: '/app/app-management',
            },
            {
              label: 'Legal Library',
              text: 'Extensive Repository of Legal Resources',
              icon: (
                <FontAwesomeIcon
                  icon={faBook}
                  color="#ff6347"
                  fontSize="20px"
                />
              ),
              route: '/app/legal-library',
            },
            {
              label: 'Legal Document Analysis',
              text: 'Advanced AI-Powered Legal Analysis',
              icon: <QueryStatsIcon sx={{ color: '#00bfd6' }} />,
              route: '/app/document-analysis',
            },
          ].map((item, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
                onClick={() => handleCardClick(item.route)}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    backgroundColor:
                      item.label === 'Case Management' ? '#e0e0e0' : '#fff',
                    borderRadius: '12px',
                    width: '100%',
                    height: 90,
                    padding: '12px',
                    boxShadow: '0 8px 24px rgba(0, 0, 0, 0.2)',
                    backdropFilter: 'blur(15px)',
                    transition:
                      item.label === 'Case Management'
                        ? 'none'
                        : 'transform 0.3s ease, box-shadow 0.3s ease',
                    cursor:
                      item.label === 'Case Management'
                        ? 'not-allowed'
                        : 'pointer',
                    '&:hover':
                      item.label === 'Case Management'
                        ? {}
                        : {
                            transform: 'translateY(-10px)',
                            boxShadow: '0 12px 32px rgba(0, 0, 0, 0.3)',
                          },
                    '&:active':
                      item.label === 'Case Management'
                        ? {}
                        : {
                            transform: 'translateY(0)',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                          },
                    '& img': {
                      marginBottom: '12px',
                    },
                  }}
                >
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    {item.icon}
                    <Typography
                      //variant="body2"
                      sx={{
                        fontWeight: 'bold',
                        color: '#36454f',
                        fontSize: '12px',
                      }}
                    >
                      {item.label}
                    </Typography>
                  </Stack>
                  <Typography
                    //variant="body2"
                    sx={{
                      textAlign: 'start',
                      fontSize: '10px',
                      mt: 1,
                      //paddingBottom: '4px',
                      color: 'gray',
                      height: '36px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                    }}
                  >
                    {item.text}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default HomeMainPage;
