export const applicationHistoryReducer = (state = {}, action) => {
  switch (action.type) {
    case "APPLICATION_HISTORY_REQUEST":
      return { loading: true };
    case "APPLICATION_HISTORY_SUCCESS":
      return {
        loading: false,
        history: action.payload,
        historyReset: false,
        chatId: action.payload.chatId,
      };
    case "APPLICATION_HISTORY_FAIL":
      return { loading: false, error: action.payload };
    case "APPLICATION_HISTORY_RESET":
      return {};
    default:
      return state;
  }
};

export const editApplicationTitleReducer = (state = {}, action) => {
  switch (action.type) {
    case "EDIT_APPLICATION_TITLE_REQUEST":
      return { loading: true };
    case "EDIT_APPLICATION_TITLE_SUCCESS":
      return { loading: false, message: action.payload, success: true };
    case "EDIT_APPLICATION_TITLE_FAIL":
      return { loading: false, error: action.payload };
    case "EDIT_APPLICATION_TITLE_RESET":
      return {};
    default:
      return state;
  }
};

export const deleteApplicationReducer = (state = {}, action) => {
  switch (action.type) {
    case "DELETE_APPLICATION_REQUEST":
      return { loading: true };
    case "DELETE_APPLICATION_SUCCESS":
      return { loading: false, message: action.payload, success: true };
    case "DELETE_APPLICATION_FAIL":
      return { loading: false, error: action.payload };
    case "DELETE_APPLICATION_RESET":
      return {};
    default:
      return state;
  }
};

export const newapplicationReducer = (state = {}, action) => {
  switch (action.type) {
    case "NEW_APPLICATION_REQUEST":
      return { loading: true };
    case "NEW_APPLICATION_SUCCESS":
      return { loading: false, isClicked: true };
    case "NEW_APPLICATION_FAIL":
      return { loading: false, error: action.payload };
    case "NEW_APPLICATION_RESET":
      return {};
    default:
      return state;
  }
};

export const applicationTitlesReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_APPLICATION_TITLES_REQUEST":
      return { loading: true };
    case "GET_APPLICATION_TITLES_SUCCESS":
      return { loading: false, titles: action.payload };
    case "GET_APPLICATION_TITLES_FAIL":
      return { loading: false, error: action.payload };
    case "GET_APPLICATION_TITLES_RESET":
      return {};
    default:
      return state;
  }
};
