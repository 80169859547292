import React from "react";
import {
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Box,
  Typography,
} from "@mui/material";

const DynamicForm = ({
  formConfig,
  formData,
  handleInputChange,
  handleRadioChange,
}) => {
  return (
    <form id={formConfig?.form?.id}>
      <Grid container spacing={2}>
        {formConfig.form.fields.map((field, index) => {
          // Skip rendering if the previous field is a radio button to avoid duplication
          if (
            index > 0 &&
            formConfig.form.fields[index - 1]?.type === "radio"
          ) {
            return null;
          }

          // Handle radio buttons with adjacent fields
          if (field.type === "radio") {
            const nextField = formConfig.form.fields[index + 1];
            return (
              <Grid item xs={12} key={field.id}>
                <RadioGroup
                  row
                  name={field.name}
                  onChange={(e) => handleRadioChange(e, field.name)}
                >
                  {field.options.map((option) => (
                    <FormControlLabel
                      key={option.value}
                      required={field.required}
                      value={option.value}
                      control={<Radio required={field.required} />}
                      label={option.label}
                    />
                  ))}
                </RadioGroup>
                {nextField && (
                  <Box sx={{ marginTop: 2 }}>
                    {renderField(nextField, formData, handleInputChange)}
                  </Box>
                )}
              </Grid>
            );
          }

          // Handle textarea fields separately
          if (field.type === "textarea") {
            return (
              <Grid item xs={12} key={field.id}>
                {renderField(field, formData, handleInputChange)}
              </Grid>
            );
          }

          if (field.type === "list") {
            return (
              <Grid item xs={12} key={field.id}>
                <Typography>{field.label}</Typography>
                {field.fields.map((input) => {
                  return renderField(input, formData, handleInputChange);
                })}
              </Grid>
            );
          }

          // Render all other fields normally
          return (
            <Grid item xs={12} sm={6} key={field.id}>
              {renderField(field, formData, handleInputChange)}
            </Grid>
          );
        })}
      </Grid>
    </form>
  );
};

// Helper function to render fields based on type
const renderField = (field, formData, handleInputChange) => {
  switch (field.type) {
    case "text":
      return (
        <TextField
          size="small"
          sx={{ backgroundColor: "white" }}
          required={field.required}
          label={field.label}
          id={field.id}
          name={field.name}
          value={formData[field.name]}
          onChange={handleInputChange}
          variant="outlined"
          fullWidth
        />
      );
    case "date":
      return (
        <TextField
          size="small"
          sx={{ backgroundColor: "white" }}
          required={field.required}
          label={field.label}
          id={field.id}
          name={field.name}
          type="date"
          value={formData[field.name]}
          onChange={handleInputChange}
          variant="outlined"
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
      );
    case "textarea":
      return (
        <TextField
          size="small"
          required={field.required}
          sx={{ backgroundColor: "white" }}
          id={field.id}
          name={field.name}
          label={field.label}
          value={formData[field.name]}
          onChange={handleInputChange}
          variant="outlined"
          multiline
          minRows={4}
          fullWidth
        />
      );

    default:
      return null;
  }
};

export default DynamicForm;
