import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    PopperProps={{
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, -4],
          },
        },
      ],
    }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#303f9f',
    color: 'white',
    fontSize: '12px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#303f9f',
  },
}));

export default CustomTooltip;
