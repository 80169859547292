import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  DialogActions,
} from '@mui/material';

const InfoDialog = ({ open, handleClose, isStatuteSearch }) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{ color: '#2980b9', fontWeight: 'bold', textAlign: 'center' }}
      >
        Search Information
      </DialogTitle>
      <DialogContent
        sx={{
          overflowY: 'auto',
          scrollBehavior: 'smooth',
          '::-webkit-scrollbar': {
            width: '5px',
          },
          '::-webkit-scrollbar-track': {
            background: '#f1f1f1',
          },
          '::-webkit-scrollbar-thumb': {
            background: '#101936',
            borderRadius: '6px',
          },
        }}
      >
        <Typography variant="h6" color="#2980b9" fontSize="18px" gutterBottom>
          Keyword Search
        </Typography>
        <Typography paragraph>
          LawGPT enables you to search for specific words and phrases within the
          original judgment text or titles. By default, the search results match
          the exact words of your query with those in the original judgment.
        </Typography>

        <Typography variant="subtitle1" gutterBottom>
          Refining Your <span>{isStatuteSearch ? 'Statute' : 'Legal'}</span>{' '}
          Search Queries:
        </Typography>
        <Typography component="ul" style={{ paddingLeft: '20px' }}>
          <Typography component="li" mb="6px">
            <strong>ANDD:</strong> Enter "arrest ANDD bail" to return judgments
            that include both "arrest" and "bail."
          </Typography>
          <Typography component="li" mb="6px">
            <strong>ORR:</strong> Use "damages ORR compensation" to display
            judgments containing either "damages" or "compensation."
          </Typography>
          <Typography component="li" mb="6px">
            <strong>NOTT:</strong> Search for "copyright NOTT patent" to include
            "copyright" while excluding "patent."
          </Typography>
          <Typography component="li" mb="6px">
            <strong>Parentheses ( ): </strong> Use parentheses, e.g., "loss AND
            (economic ORR physical)" to return judgments with either term.
          </Typography>
          <Typography component="li" mb="6px">
            <strong>Quotation Marks " ": </strong> Search for "institutional
            investors" to get judgments with the exact phrase.
          </Typography>
          <Typography component="li" mb="6px">
            <strong>Asterisk (*): </strong> Search with a wildcard like
            "constitut*" to return words like "constitution" or "constitutes."
          </Typography>
        </Typography>
        {!isStatuteSearch && (
          <>
            <Typography
              variant="h6"
              color="#2980b9"
              fontSize="18px"
              gutterBottom
            >
              AI Search
            </Typography>
            <Typography paragraph>
              AI search helps you find information quickly and effectively.
              Instead of just searching for exact words, it understands the
              meaning behind your query.
            </Typography>
            <Typography paragraph>
              <strong>Understanding Your Query:</strong> AI search analyzes what
              you're looking for by focusing on the overall meaning rather than
              specific words.
            </Typography>
            <Typography paragraph>
              <strong>Matching with Judgments:</strong> The AI compares your
              query with judgment texts, finding relevant information even if
              the exact words don’t match.
            </Typography>
            <Typography paragraph>
              <strong>Finding Relevant Results:</strong> AI search delivers
              results that better match your question, making it easier to find
              the information you need.
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="contained"
          color="error"
          sx={{
            marginRight: '24px',
            marginBottom: '8px',
            textTransform: 'none',
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InfoDialog;
