import React from "react";
import { Modal, Box, CircularProgress, Typography } from "@mui/material";

const LoadingModal = ({ open }) => {
  return (
    <Modal open={open}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          p: 4,
          outline: "none",
        }}
      >
        <CircularProgress size={50} sx={{ mb: 2, color: "#303f9f" }} />
        <Typography sx={{ color: "#303f9f" }} variant="body1">
          Loading
        </Typography>
        <Typography variant="body1">
          Processing your request, Please wait.
        </Typography>
      </Box>
    </Modal>
  );
};

export default LoadingModal;
