import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMsal } from '@azure/msal-react';
import { Box, Stack } from '@mui/system';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { logout } from '../../actions/userActions';
import AppBar from '@mui/material/AppBar';
import DropdownButton from '../UI/DropdownButton';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { instance, accounts } = useMsal();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Function to find the AccountInfo based on homeAccountId
  const findAccountByHomeAccountId = (homeAccountId) => {
    return accounts.find((account) => account.homeAccountId === homeAccountId);
  };

  const signOutClickHandler = (instance, accountToSignOut) => {
    instance.logout({
      account: accountToSignOut,
      postLogoutRedirectUri: '/',
    });
  };

  const logOut = () => {
    const homeAccountId = accounts[0]?.homeAccountId;
    const accountToSignOut = findAccountByHomeAccountId(homeAccountId);
    if (accountToSignOut) {
      signOutClickHandler(instance, accountToSignOut);
    } else {
      console.error('Account not found for the given homeAccountId');
    }
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    dispatch(logout());
    logOut();
  };

  return (
    <Box
      position="sticky"
      sx={{
        backgroundColor: '#101936',
        height: '50px',
        paddingLeft: '3.5px',
        paddingRight: '16px',
      }}
    >
      <Box
        disableGutters
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          minHeight: '50px',
          height: '50px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => navigate('/home')}
        >
          <img
            style={{ width: '100px', height: '32px' }}
            src="/images/lawgpt.png"
            alt="df-logo"
          />
        </Box>
        <Stack direction="row" paddingRight="0">
          <DropdownButton />
          <Typography
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ fontSize: '14px', marginRight: '4px', color: 'white' }}
          >
            {userInfo?.name}
          </Typography>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            sx={{ paddingRight: 0 }}
          >
            <Avatar
              sx={{ width: '35px', height: '35px' }}
              alt="user"
              src="/images/userImage.png"
            />
          </IconButton>
        </Stack>
      </Box>
      <Menu
        sx={{ marginTop: 3 }}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </Box>
  );
};

export default Header;
