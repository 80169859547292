import React, { useState } from 'react';

const SpinnerPopup = () => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div>
      {/* Popup Overlay */}
      <div style={styles.popupOverlay}>
        <div style={styles.popup}>
          {/* Loading Spinner */}
          <div style={styles.loadingSpinner}></div>

          {/* Loading Text */}
          <div style={styles.loadingText}>
            Loading<span>.</span>
            <span>.</span>
            <span>.</span>
          </div>

          {/* Additional message */}
          <p>Fetching your information, please wait...</p>
        </div>
      </div>
    </div>
  );
};

// CSS styles in JavaScript object format, including keyframe animations
const styles = {
  popupOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    animation: 'fadeIn 0.3s ease forwards',
  },
  popup: {
    background: 'white',
    borderRadius: '15px',
    padding: '30px',
    width: '400px',
    maxWidth: '90%',
    boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    animation: 'slideUp 0.5s ease forwards',
    position: 'relative',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  },
  loadingSpinner: {
    width: '70px',
    height: '70px',
    border: '8px solid rgba(0, 198, 255, 0.2)',
    borderTop: '8px solid #00c6ff',
    borderRadius: '50%',
    animation: 'spin 1.5s linear infinite',
    margin: '0 auto 20px',
  },
  loadingText: {
    fontSize: '20px',
    color: '#3C50B1',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    marginBottom: '15px',
    display: 'flex',
    justifyContent: 'center',
  },
  // Adding keyframes as part of the style object using animation shorthand
  '@keyframes spin': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
  },
  '@keyframes fadeIn': {
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
  },
  '@keyframes slideUp': {
    '0%': { transform: 'translateY(50px)', opacity: 0 },
    '100%': { transform: 'translateY(0)', opacity: 1 },
  },
};

// Inject the keyframes into a <style> tag
const KeyframeStyle = () => (
  <style>
    {`
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
    @keyframes fadeIn {
      from { opacity: 0; }
      to { opacity: 1; }
    }
    @keyframes slideUp {
      from { transform: translateY(50px); opacity: 0; }
      to { transform: translateY(0); opacity: 1; }
    }
    `}
  </style>
);

const SpinnerWithKeyframes = () => (
  <>
    <KeyframeStyle />
    <SpinnerPopup />
  </>
);

export default SpinnerWithKeyframes;
