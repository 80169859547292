import React, { useState } from "react";
import { Modal, Box, Typography, IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

const StatutePreviewModal = ({ open, onClose, statute }) => {
  const [fullscreen, setFullscreen] = useState(false);

  const handleFullscreenToggle = () => {
    setFullscreen(!fullscreen);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: fullscreen ? 0 : "50%",
          left: fullscreen ? 0 : "50%",
          transform: fullscreen ? "none" : "translate(-50%, -50%)",
          width: fullscreen ? "100vw" : "40%",
          height: fullscreen ? "100vh" : "auto",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 2,
          borderRadius: 2,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            mr: 2,
            display: "flex",
            justifyContent: "flex-end",
            "::-webkit-scrollbar": {
              width: "5px",
            },
            "::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "::-webkit-scrollbar-thumb": {
              background: "#101936",
              borderRadius: "6px",
            },
          }}
        >
          <IconButton onClick={handleFullscreenToggle}>
            {fullscreen ? (
              <FullscreenExitIcon sx={{ color: "#36454f" }} />
            ) : (
              <FullscreenIcon sx={{ color: "#36454f" }} />
            )}
          </IconButton>
          <IconButton onClick={onClose}>
            <CancelIcon color="error" />
          </IconButton>
        </Box>
        <Typography
          sx={{
            padding: 2,
            overflowY: "auto",
            maxHeight: fullscreen ? "calc(100vh - 60px)" : "60vh", // Adjusted for fullscreen
            "::-webkit-scrollbar": {
              width: "5px",
            },
            "::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "::-webkit-scrollbar-thumb": {
              background: "#101936",
              borderRadius: "6px",
            },
          }}
          dangerouslySetInnerHTML={{ __html: statute.Statute_HTML }}
        />
      </Box>
    </Modal>
  );
};

export default StatutePreviewModal;
