import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Grid,
  Checkbox,
  Card,
  TextField,
  IconButton,
  Button,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const checkboxItems = [
  { label: 'Facts', color: '#ffec94' },
  { label: 'Issues', color: '#ffaeae' },
  { label: "Petitioner's Arguments", color: '#ccffbb' },
  { label: "Respondent's Arguments", color: '#cccc99' },
  { label: 'Analysis of the Law', color: '#56baec' },
  { label: 'Precedent Analysis', color: '#b4d8e7' },
  { label: "Court's Reasoning", color: '#fce6e6' },
  { label: 'Conclusion', color: '#c0bcb6' },
];

const formatCourtName = (courtName) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(courtName, 'text/html');

  const formatTextContent = (name) => {
    return name
      .toLowerCase()
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const walkNodes = (node) => {
    node.childNodes.forEach((child) => {
      if (child.nodeType === Node.TEXT_NODE) {
        child.nodeValue = formatTextContent(child.nodeValue);
      } else if (child.nodeType === Node.ELEMENT_NODE) {
        walkNodes(child);
      }
    });
  };

  walkNodes(doc.body);

  return doc.body.innerHTML;
};

const formatJudgeName = (names) => {
  if (!names || names.length === 0) return '';

  // Check if names is an array, if not treat it as a single name
  if (!Array.isArray(names)) {
    names = [names];
  }

  // Convert each name to title case and join them with commas
  const titleCaseNames = names
    .map((name) =>
      name
        .toLowerCase()
        .split(' ')
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(' ')
    )
    .join(', ');

  return titleCaseNames;
};

const formatCaseTitle = (title) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(title, 'text/html');

  const formatTextContent = (text) => {
    return text
      .split('Honorable')[0]
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const walkNodes = (node) => {
    node.childNodes.forEach((child) => {
      if (child.nodeType === Node.TEXT_NODE) {
        child.nodeValue = formatTextContent(child.nodeValue);
      } else if (child.nodeType === Node.ELEMENT_NODE) {
        walkNodes(child);
      }
    });
  };

  walkNodes(doc.body);

  return doc.body.innerHTML;
};

const CaseDetail = () => {
  const navigate = useNavigate();
  const caseDetail = useSelector((state) => state.caseDetail);
  const { caseItem } = caseDetail;

  const [checkedItems, setCheckedItems] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [highlightedElements, setHighlightedElements] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const highlights = document.querySelectorAll('.highlight');
    setHighlightedElements(highlights);

    if (highlights.length > 0) {
      highlights.forEach((el, index) => {
        if (index === currentIndex) {
          el.style.backgroundColor = 'orange';
          el.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else {
          el.style.backgroundColor = 'yellow';
        }
      });
    }

    // Cleanup function
    return () => {
      setCurrentIndex(0);
    };
  }, [searchTerm, currentIndex]);

  const handleNextMatch = () => {
    if (highlightedElements.length > 0) {
      const nextIndex = (currentIndex + 1) % highlightedElements.length;
      setCurrentIndex(nextIndex);
    }
  };

  const handleCheckboxChange = (label) => {
    setCheckedItems((prev) => ({
      ...prev,
      [label]: !prev[label],
    }));
  };

  const highlightText = (html, search) => {
    if (!search) return html;

    // Escape special characters in the search term
    const escapedTerm = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(
      `(${escapedTerm.replace(/\s+/g, '(\\s|<[^>]+>|\\n|\\r)*')})`,
      'gi'
    );

    // Function to highlight text recursively
    const highlightNode = (node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        const highlightedText = node.textContent.replace(
          regex,
          '<span class="highlight" style="background-color: orange; font-weight: bold;">$1</span>'
        );
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = highlightedText;
        return tempDiv.innerHTML;
      }

      if (node.nodeType === Node.ELEMENT_NODE) {
        const children = Array.from(node.childNodes)
          .map(highlightNode)
          .join('');
        const element = document.createElement(node.nodeName.toLowerCase());
        Array.from(node.attributes).forEach((attr) => {
          element.setAttribute(attr.name, attr.value);
        });
        element.innerHTML = children;
        return element.outerHTML;
      }

      return '';
    };

    // Parse the input HTML and highlight matches
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    const highlightedHtml = Array.from(tempDiv.childNodes)
      .map(highlightNode)
      .join('');

    return highlightedHtml;
  };

  const getContentAfterHeadings = (html) => {
    // Parse the HTML string to a DOM structure
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    // Find elements that could contain "ORDER" or "JUDGEMENT"
    const allElements = Array.from(doc.querySelectorAll('h1, h2, p, b'));

    // Look for "ORDER" or "JUDGEMENT" inside these elements (including nested elements)
    const targetElement = allElements.find(
      (el) =>
        el.textContent.trim().includes('ORDER') ||
        el.textContent.trim().includes('JUDGEMENT') ||
        el.textContent.trim().includes('JUDGMENT')
    );

    // Create an array to store the content after "ORDER" or "JUDGEMENT"
    let contentAfterHeadings = [];

    if (targetElement) {
      // Traverse the sibling elements after the found target element
      let nextElement =
        targetElement.closest('p, h1, h2, b').nextElementSibling;
      while (nextElement) {
        contentAfterHeadings.push(nextElement.outerHTML);
        nextElement = nextElement.nextElementSibling;
      }
    }

    // Join the content and return
    return contentAfterHeadings.join('');
  };

  const caseDescriptionWithStyles = `
  <style>
    .MsoNormal {
      font-family: 'Roboto', Helvetica, Arial, sans-serif !important;
      font-size: 14px;
      text-align: 'justify';
    }
     .MsoNormal b {
      color: #303f9f;
    }
  </style>
  ${highlightText(
    getContentAfterHeadings(
      caseItem?.Case_Description_HTML ||
        caseItem?.metadata.Case_Description_HTML
    ),
    searchTerm
  )}
`;

  console.log(caseItem?.Case_Description_HTML);

  const caseMetaData = [
    {
      label: 'Title',
      value: formatCaseTitle(caseItem?.Title || caseItem?.metadata.Title),
    },
    {
      label: 'Citation',
      value: caseItem?.Citation_Name || caseItem?.metadata.Citation_Name,
    },
    {
      label: 'Court',
      value: formatCourtName(caseItem?.Court || caseItem?.metadata.Court),
    },
    {
      label: 'Judge',
      value: formatJudgeName(
        caseItem?.Judge_Name || caseItem?.metadata.Judge_Name
      ),
    },
    {
      label: 'Appellant',
      value: formatCaseTitle(caseItem?.Title).split('Versus')[0],
    },
    {
      label: 'Respondents',
      value: formatCaseTitle(caseItem?.Title).split('Versus')[1],
    },
  ];

  const filteredMetaData = caseMetaData.filter((detail) => detail.value);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 50px)',
        flex: 5,
        flexGrow: 1,
        boxSizing: 'border-box',
        bgcolor: '#f5f5f5',
      }}
    >
      <React.Fragment>
        {/* Case Tags Card */}
        <Box sx={{ display: 'flex', ml: 1 }}>
          <Button
            sx={{
              textTransform: 'none',
              m: '8px 4px',
              color: '#303f9f',
              fontWeight: 'bold',
            }}
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate('/app/searchResults')}
          >
            Back
          </Button>
        </Box>
        <Box
          sx={{
            margin: '0 8px',
            backgroundColor: '#f5f5f5',
          }}
        >
          <Card
            sx={{
              overflow: 'visible',
              display: 'flex',
              flexDirection: 'column',
              p: '8px',
              //marginTop: 1,
              bgcolor: '#fff',
              boxShadow: '1px 2px 6px 0px #00000020',
              borderRadius: '8px',
            }}
          >
            <Typography
              variant="caption"
              sx={{
                display: 'flex',
                fontWeight: 'bold',
                marginBottom: '6px',
              }}
            >
              Select the following parts of the judgement
            </Typography>
            <Grid container spacing={2}>
              {checkboxItems.map(({ label, color }) => (
                <Grid item xs={3} key={label}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      bgcolor: color,
                      p: '5px',
                      borderRadius: '4px',
                    }}
                  >
                    <Checkbox
                      size="small"
                      checked={checkedItems[label] || false}
                      onChange={() => handleCheckboxChange(label)}
                      sx={{
                        p: 0,
                      }}
                    />
                    <Typography
                      sx={{
                        color: '#000',
                        ml: '4px',
                        fontWeight: 'bold',
                        fontSize: '13px',
                      }}
                    >
                      {label}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Card>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              bgcolor: '#f5f5f5',
            }}
          >
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{
                backgroundColor: 'white',
                margin: '16px 2px 8px 2px',
                borderRadius: '24px',
                '& .MuiInputBase-root': {
                  height: '40px',
                  fontSize: '14px',
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: '24px',
                },
              }}
              InputProps={{
                endAdornment: <SearchIcon />,
              }}
            />
            <IconButton
              onClick={handleNextMatch}
              disabled={highlightedElements.length === 0}
            >
              <ExpandMore />
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{
            overflowY: 'auto',
            bgcolor: '#fff',
            p: '12px 12px',
            m: 1,
            borderRadius: '8px',
            '::-webkit-scrollbar': {
              width: '5px',
            },
            '::-webkit-scrollbar-track': {
              background: '#f1f1f1',
            },
            '::-webkit-scrollbar-thumb': {
              background: '#bbb',
              borderRadius: '6px',
            },
          }}
        >
          {filteredMetaData.map((detail, index) => (
            <Box
              key={index}
              sx={{
                bgcolor: index % 2 === 0 ? '#f5f5f5' : '#fff',
                paddingLeft: '5px',
                marginBottom: '8px',
              }}
            >
              <Typography sx={{ fontSize: '14px' }}>
                <span style={{ fontWeight: 'bold' }}>{detail.label}:</span>{' '}
                <span>{detail.value}</span>
              </Typography>
            </Box>
          ))}
          <Box
            dangerouslySetInnerHTML={{
              __html: caseDescriptionWithStyles,
            }}
          />
        </Box>
      </React.Fragment>
    </Box>
  );
};

export default CaseDetail;
