import React, { useState, useEffect } from 'react';
import {
  List,
  ListItem,
  IconButton,
  Box,
  Stack,
  Typography,
  TextField,
  Button,
  Modal,
  ListItemButton,
  Menu,
  MenuItem,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from 'react-redux';
import LoadingModal from './LoadingModal';

const functionAppUrl =
  'https://rg-law-gpt-be-auth2.azurewebsites.net/api/getUserBookmarkGroups';
const createGroupUrl =
  'https://rg-law-gpt-be-auth2.azurewebsites.net/api/createBookmarkGroup';
const deleteGroupUrl =
  'https://rg-law-gpt-be-auth2.azurewebsites.net/api/deleteBookmarkGroup';
const renameGroupUrl =
  'https://rg-law-gpt-be-auth2.azurewebsites.net/api/renameBookmarkGroup';

const BookmarkSidebar = ({ onGroupSelect }) => {
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [editingGroup, setEditingGroup] = useState(null);
  const [newGroupName, setNewGroupName] = useState('');
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [groupNameInput, setGroupNameInput] = useState('');
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuTargetGroup, setMenuTargetGroup] = useState(null);
  const inputRef = React.useRef(null);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const handleGroupClick = (event, groupId) => {
    event.stopPropagation();
    setSelectedGroup(groupId);
    onGroupSelect(groupId);
    setEditingGroup(null);
    setMenuAnchorEl(null);
  };

  const confirmDeleteGroup = (e, groupId) => {
    e.stopPropagation();
    setGroupToDelete(groupId);
    setConfirmationModalOpen(true);
    handleMenuClose();
  };

  const handleDeleteGroup = async () => {
    const groupId = groupToDelete;
    const groupName = groups.find((group) => group._id === groupId).groupName;

    if (groupName === 'Bookmarks') return;

    try {
      const response = await fetch(deleteGroupUrl, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: userInfo._id,
          groupId: groupId,
        }),
      });

      if (response.ok) {
        setGroups(groups.filter((group) => group._id !== groupId));
        if (selectedGroup === groupId) {
          setSelectedGroup(groups[0]?._id || null);
          onGroupSelect(groups[0]?._id || null);
        }
      } else {
        console.error('Failed to delete the group');
      }
    } catch (error) {
      console.error('Error deleting the group:', error);
    } finally {
      setConfirmationModalOpen(false);
    }
  };

  const handleRenameGroup = async (e, groupId) => {
    e.stopPropagation();
    try {
      const response = await fetch(renameGroupUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: userInfo._id,
          groupId: groupId,
          newGroupName: newGroupName,
        }),
      });

      if (response.ok) {
        setGroups(
          groups.map((group) =>
            group._id === groupId
              ? { ...group, groupName: newGroupName }
              : group
          )
        );
        setEditingGroup({ groupId: '', index: -1 });
      } else {
        console.error('Failed to rename the group');
      }
    } catch (error) {
      console.error('Error renaming the group:', error);
    }
  };

  const handleCreateGroup = async () => {
    if (!groupNameInput) return;

    try {
      const response = await fetch(createGroupUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: userInfo._id,
          groupName: groupNameInput,
        }),
      });

      const result = await response.json();
      if (response.ok) {
        setGroups([
          ...groups,
          { _id: result.groupId, groupName: groupNameInput },
        ]);
        setModalOpen(false);
        setGroupNameInput('');
      } else {
        console.error('Failed to create the group:', result.message);
      }
    } catch (error) {
      console.error('Error creating the group:', error);
    }
  };

  const handleMenuOpen = (event, groupId) => {
    event.stopPropagation();
    setMenuTargetGroup(groupId);
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setMenuTargetGroup(null);
  };

  const handleCancelEdit = (event) => {
    event.stopPropagation();
    setNewGroupName('');
    setEditingGroup(null);
  };

  const handleEditTitle = (event, groupId, groupName) => {
    event.stopPropagation();
    setEditingGroup(groupId);
    setNewGroupName(groupName);
    handleMenuClose();
  };

  const handleInputChange = (e) => {
    const cursorPosition = inputRef.current.selectionStart;
    setNewGroupName(e.target.value);

    // Set the cursor back to where it was
    setTimeout(() => {
      inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
    }, 0);
  };

  useEffect(() => {
    const fetchGroups = async () => {
      setLoading(true);
      try {
        const userId = userInfo._id;
        const response = await fetch(`${functionAppUrl}?userId=${userId}`);
        const data = await response.json();
        setGroups(data);

        if (data.length > 0) {
          const firstGroupId = data[0]._id;
          setSelectedGroup(firstGroupId);
          onGroupSelect(firstGroupId);
        }
      } catch (error) {
        console.error('Error fetching bookmark groups:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchGroups();
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: '278px',
        boxSizing: 'border-box',
        p: '12px 0px 0 12px',
        flex: 1,
        bgcolor: '#f5f5f5',
        height: 'calc(100vh - 50px)',
      }}
    >
      <LoadingModal open={loading} />
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          bgcolor: '#fff',
          p: '10px',
          borderRadius: '10px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          overflowY: 'auto',
          flexGrow: 1,
        }}
      >
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setModalOpen(true)}
          sx={{
            backgroundColor: '#003D78',
            borderRadius: '8px',
            textTransform: 'none',
            fontWeight: 'bold',
            marginTop: '8px',
            '&:hover': { backgroundColor: 'primary.main', color: '#fff' },
            transition: 'background-color 0.3s ease-in-out',
          }}
        >
          Create Group
        </Button>

        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '4px 16px 0px 8px',
            color: '#3C50B1',
            fontWeight: 'bold',
            textTransform: 'none',
            mt: 3,
          }}
          variant="subtitle1"
        >
          Bookmark Groups
        </Typography>

        <List
          sx={{
            overflowY: 'auto',
            '::-webkit-scrollbar': {
              width: '5px',
            },
            '::-webkit-scrollbar-track': {
              background: '#fff',
            },
            '::-webkit-scrollbar-thumb': {
              background: '#bbb',
              borderRadius: '6px',
            },
          }}
        >
          {groups.map((group, index) => (
            <ListItem key={group._id} disablePadding>
              <ListItemButton
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding:
                    selectedGroup === group._id ? '0px 12px' : '8px 12px',
                  borderRadius: '8px',
                  mr: 1,
                  '&.Mui-selected': {
                    backgroundColor: '#ECECEC',
                    '&:hover': {
                      backgroundColor: '#ECECEC',
                    },
                  },
                  transition: 'background-color 0.3s ease',
                  '&:hover': {
                    backgroundColor: '#ECECEC',
                  },
                }}
                selected={
                  selectedGroup === group._id || editingGroup === group._id
                }
                onClick={(e) => handleGroupClick(e, group._id)}
              >
                {editingGroup === group._id ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <input
                      ref={inputRef}
                      style={{
                        width: '100%',
                        padding: '4px',
                        marginLeft: '0',
                        fontSize: '13px',
                      }}
                      type="text"
                      autoFocus
                      value={newGroupName}
                      onClick={(e) => e.stopPropagation()}
                      onFocus={(e) => e.stopPropagation()}
                      onChange={handleInputChange}
                    />
                    <IconButton
                      edge="end"
                      onClick={(e) => handleRenameGroup(e, group._id)}
                    >
                      <CheckIcon fontSize="small" />
                    </IconButton>
                    <IconButton edge="end" onClick={(e) => handleCancelEdit(e)}>
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Box>
                ) : (
                  <>
                    <Stack
                      direction="row"
                      width="100%"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography
                        variant="subtitle2"
                        noWrap
                        sx={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          fontSize: '13px',
                          flexGrow: 1,
                        }}
                      >
                        {group.groupName}
                      </Typography>
                      {selectedGroup === group._id && (
                        <IconButton
                          aria-label="options"
                          onClick={(e) => {
                            handleMenuOpen(e, group._id);
                          }}
                        >
                          <MoreHorizIcon />
                        </IconButton>
                      )}
                    </Stack>
                    <Menu
                      anchorEl={menuAnchorEl}
                      open={Boolean(menuAnchorEl)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem
                        sx={{ fontSize: '14px' }}
                        onClick={(e) => {
                          const group = groups.find(
                            (g) => g._id === menuTargetGroup
                          );
                          handleEditTitle(e, menuTargetGroup, group?.groupName);
                        }}
                      >
                        <EditIcon sx={{ fontSize: 16, mr: 1 }} />
                        Edit
                      </MenuItem>
                      <MenuItem
                        sx={{
                          fontSize: '14px',
                          color: '#d32f2f',
                        }}
                        onClick={(e) => confirmDeleteGroup(e, menuTargetGroup)}
                      >
                        <DeleteIcon
                          color="error"
                          sx={{ fontSize: 16, mr: 1 }}
                        />
                        Delete
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>

      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'white',
            p: 4,
            boxShadow: 24,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6">Create New Group</Typography>
          <TextField
            label="Group Name"
            value={groupNameInput}
            onChange={(e) => setGroupNameInput(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Button
            variant="contained"
            onClick={handleCreateGroup}
            sx={{ mt: 2, width: '100%' }}
          >
            Create
          </Button>
        </Box>
      </Modal>

      <Modal
        open={confirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'white',
            p: 4,
            boxShadow: 24,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Confirm Deletion
          </Typography>
          <Typography variant="body1" gutterBottom>
            Are you sure you want to delete this group?
          </Typography>
          <Box sx={{ display: 'flex', mt: 2, justifyContent: 'flex-end' }}>
            <Button
              sx={{ bgcolor: '#303f9f' }}
              variant="contained"
              onClick={handleDeleteGroup}
            >
              Yes
            </Button>
            <Button
              sx={{ ml: 2 }}
              variant="contained"
              color="error"
              onClick={() => setConfirmationModalOpen(false)}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default BookmarkSidebar;
