import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  IconButton,
  Tooltip,
} from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ChatIcon from '@mui/icons-material/Chat';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import GavelIcon from '@mui/icons-material/Gavel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWpforms } from '@fortawesome/free-brands-svg-icons';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import DescriptionIcon from '@mui/icons-material/Description';
import { faBook } from '@fortawesome/free-solid-svg-icons';

const HomeSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState('Home');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    switch (item) {
      case 'Home':
        navigate('/home');
        break;
      case 'Legal Chat':
        navigate('/app/legal-chat');
        break;
      case 'Case Law Search':
        navigate('/app/searchResults');
        break;
      case 'Statute Search':
        navigate('/app/statute-search');
        break;
      case 'Petition Management':
        navigate('/app/petition');
        break;
      case 'Application Management':
        navigate('/app/app-management');
        break;
      case 'Bookmarks':
        navigate('/app/bookmarks');
        break;
      case 'Legal Library':
        navigate('/app/legal-library');
        break;
      default:
        navigate('/app/document-analysis');
        break;
    }
  };

  useEffect(() => {
    switch (location.pathname) {
      case '/home':
        setSelectedItem('Home');
        break;
      case '/app/legal-chat':
        setSelectedItem('Legal Chat');
        break;
      case '/app/searchResults':
        setSelectedItem('Case Law Search');
        break;
      case '/app/statute-search':
        setSelectedItem('Statute Search');
        break;
      case '/app/petition':
        setSelectedItem('Petition Management');
        break;
      case '/app/bookmarks':
        setSelectedItem('Bookmarks');
        break;
      case '/app/document-analysis':
        setSelectedItem('Legal Document Analysis');
        break;
      case '/app/legal-library':
        setSelectedItem('Legal Library');
        break;
      default:
        break;
    }
  }, [location]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const menuItems = [
    { label: 'Home', icon: <OtherHousesIcon fontSize="small" /> },
    { label: 'Legal Chat', icon: <ChatIcon /> },
    { label: 'Case Law Search', icon: <GavelIcon /> },
    { label: 'Statute Search', icon: <MenuBookOutlinedIcon /> },
    { label: 'Bookmarks', icon: <BookmarkIcon /> },
    { label: 'Petition Management', icon: <AssignmentIcon /> },
    {
      label: 'Application Management',
      icon: <DescriptionIcon />,
    },
    {
      label: 'Legal Library',
      icon: <FontAwesomeIcon icon={faBook} fontSize="20px" />,
    },
    { label: 'Legal Document Analysis', icon: <QueryStatsIcon /> },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'calc(100vh - 50px)',
        backgroundColor: '#fff',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          boxSizing: 'border-box',
          flexDirection: 'column',
          flex: 1,
          minWidth: isSidebarOpen ? '278px' : '50px',
          transition: 'min-width 0.6s ease, width 0.6s ease',
          p: '5px 8px 5px 8px',
          backgroundColor: '#fff',
          borderRight: '1px solid #ccc',
          width: !isSidebarOpen ? '50px' : '278px',
        }}
      >
        <List sx={{ flexGrow: 1 }}>
          {menuItems.map((item) => (
            <ListItem key={item.label} disablePadding>
              <Tooltip
                title={item.label}
                placement="right"
                disableInteractive
                arrow
                PopperProps={{
                  sx: {
                    '& .MuiTooltip-tooltip': {
                      backgroundColor: '#101936',
                    },
                    '& .MuiTooltip-arrow': {
                      color: '#101936',
                    },
                  },
                }}
                sx={{ display: isSidebarOpen ? 'none' : 'block' }}
              >
                <ListItemButton
                  onClick={() => handleItemClick(item.label)}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 1,
                    color:
                      selectedItem === item.label ? 'primary.main' : '#36454f',
                    backgroundColor:
                      selectedItem === item.label ? '#F5F5F5' : 'transparent',
                    '&:hover': {
                      backgroundColor: '#e6f2fb',
                    },
                    borderRadius: 1,
                    padding: 1,
                  }}
                  disabled={item.disabled}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      color: '#3c50b1',
                      mr: isSidebarOpen ? 3 : 0,
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  {isSidebarOpen && (
                    <ListItemText
                      primary={item.label}
                      primaryTypographyProps={{
                        color: '#36454f',
                        //fontFamily: 'poppins, sans-serif',
                        fontWeight:
                          selectedItem === item.label ? 'bold' : 'normal',
                      }}
                    />
                  )}
                </ListItemButton>
              </Tooltip>
            </ListItem>
          ))}
        </List>
        <Box
          sx={{
            backgroundColor: '#fff',
            display: 'flex',
            p: 1,
            flexDirection: 'row',
            justifyContent: 'end',
          }}
        >
          <IconButton
            sx={{ color: '#36454f' }}
            onClick={toggleSidebar}
            color="inherit"
          >
            {isSidebarOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default HomeSidebar;
