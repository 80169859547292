import React, { useState } from 'react';
import { Box, ListItemIcon, ListItemText, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HistoryIcon from '@mui/icons-material/History';

const PetitionSidebar = ({ onPetitionTabClick, selectedTab }) => {
  const handleListItemClick = (index) => {
    onPetitionTabClick(index);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        flex: 1,
        height: 'calc(100vh - 50px)',
        minWidth: '278px',
        p: '12px 8px 0 8px',
        '::-webkit-scrollbar': {
          width: '5px',
        },
        '::-webkit-scrollbar-track': {
          background: '#f1f1f1',
        },
        '::-webkit-scrollbar-thumb': {
          background: '#101936',
          borderRadius: '6px',
        },
      }}
    >
      <Typography variant="h6" sx={{ color: '#303f9f', mt: 2, mb: 2 }}>
        Petition Management
      </Typography>
      <List>
        {[
          {
            label: 'Create Petition',
            icon: <AddCircleOutlineIcon fontSize="small" />,
          },
          { label: 'Petition History', icon: <HistoryIcon fontSize="small" /> },
        ].map((item, index) => (
          <ListItem key={item.label} disablePadding>
            <ListItemButton
              onClick={() => handleListItemClick(index)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 1,
                borderRadius: 1,
                padding: '4px',
                backgroundColor:
                  selectedTab === index ? '#303f9f' : 'transparent',
                '&:hover': {
                  backgroundColor:
                    selectedTab === index ? '#303f9f' : '#f5f5f5',
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  color: selectedTab === index ? 'white' : '#303f9f',
                  mr: 2,
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.label}
                primaryTypographyProps={{
                  color: selectedTab === index ? 'white' : '#303f9f',
                  fontWeight: selectedTab === index ? 'bold' : 'normal',
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default PetitionSidebar;
