import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TableSortLabel,
  TablePagination,
  CircularProgress,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import SendIcon from '@mui/icons-material/Send';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';
import dayjs from 'dayjs';
import { green, red, orange, grey } from '@mui/material/colors';
import { deletePetition, getPetitionTitles } from '../actions/petitionActions';
import HTMLDocx from 'html-docx-js/dist/html-docx';
import { saveAs } from 'file-saver';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PetitionEditor from './PetitionEditor';
import PetitionPreview from './UI/PetitionPreview';
import config from '../config/api';
import CustomTooltip from './UI/CustomTooltip';
import DynamicForm from './UI/DynamicForm';

const PetitionForm = ({ selectedPetitionTab, onPetitionTabClick }) => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const petitionTitles = useSelector((state) => state.petitionTitles);
  const { titles, error } = petitionTitles;

  const deletePetitionStatus = useSelector((state) => state.deletePetition);
  const { success: successDelete } = deletePetitionStatus;

  const [isEditing, setIsEditing] = useState(false);
  const [petitionList, setPetitionList] = useState([]);
  const [isGenerateClicked, setIsGenerateClicked] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('timecreated');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showPreview, setShowPreview] = useState(false);
  const [petitionTypes, setPetitionTypes] = useState([]);
  const [formConfig, setFormConfig] = useState({});

  const [formData, setFormData] = useState(() => {
    const initialData = {};
    formConfig.fields?.forEach((field) => {
      initialData[field.name] = field.value || '';
    });
    return initialData;
  });

  const [selectedPetitionType, setSelectedPetitionType] = useState({});
  const [generatedPetition, setGeneratedPetition] = useState('');
  const [previewContent, setPreviewContent] = useState('');
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [petitionId, setPetitionId] = useState('');
  const [editedContent, setEditedContent] = useState('');
  const [isPetitionTypesLoading, setIsPetitionTypesLoading] = useState(false);

  const getEditedContent = (content) => {
    setEditedContent(content);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleRadioChange = (e, fieldName) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: e.target.value,
    }));
  };

  const handlePreview = async (content) => {
    setPreviewContent(content);
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleBackToForm = () => {
    setIsGenerateClicked(false);
  };

  const handleSave = async () => {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    const endpoint = `${config.petitionForm}/UpdatePetition`;
    const requestPayload = {
      user_id: userInfo._id,
      chat_id: petitionId,
      Document: editedContent,
      Timestamp: dayjs().format('DD-MM-YYYY'),
    };
    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(requestPayload),
        redirect: 'follow',
      });
      if (response.ok) {
        const generatedPetition = await response.text();
        console.log(generatedPetition);
        setGeneratedPetition(generatedPetition);
        setIsEditing(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditPetition = (id, content) => {
    setIsEditing(true);
    onPetitionTabClick(0);
    setGeneratedPetition(content);
    setPetitionId(id);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getFilteredData = () => {
    return petitionList?.filter((row) =>
      row.Title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const getSortedData = () => {
    return getFilteredData()
      .slice()
      .sort((a, b) => {
        if (orderBy === 'timecreated') {
          const dateA = new Date(a[orderBy]);
          const dateB = new Date(b[orderBy]);

          return order === 'asc' ? dateA - dateB : dateB - dateA;
        }
        if (a[orderBy] < b[orderBy]) {
          return order === 'asc' ? -1 : 1;
        }
        if (a[orderBy] > b[orderBy]) {
          return order === 'asc' ? 1 : -1;
        }
        return 0;
      });
  };

  const paginatedData = getSortedData().slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleDeletePetition = (event, petition_id) => {
    event.stopPropagation();
    const userConfirmed = window.confirm(
      'Are you sure you want to delete this chat?'
    );
    if (userConfirmed) {
      dispatch(deletePetition(userInfo._id, petition_id));
      dispatch({ type: 'CHAT_HISTORY_RESET' });
    }
  };

  const cleanQuillOutput = (html) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    div.querySelectorAll('.ql-align-center, .ql-align-right').forEach((el) => {
      if (el.classList.contains('ql-align-center')) {
        el.style.textAlign = 'center';
      }
      if (el.classList.contains('ql-align-right')) {
        el.style.textAlign = 'right';
      }
    });
    return div.innerHTML;
  };

  const downloadDocx = (content, title) => {
    // const cleanedHtml = cleanQuillOutput(content);
    const docxBlob = HTMLDocx.asBlob(content);
    saveAs(docxBlob, `${title}.docx`);
  };

  const handlePrint = () => {
    const printContent = cleanQuillOutput(generatedPetition);

    // Create a hidden iframe
    const iframe = document.createElement('iframe');
    iframe.style.position = 'absolute';
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.style.border = '0';
    document.body.appendChild(iframe);

    // Load the print content into the iframe
    const iframeDoc = iframe.contentWindow.document;
    iframeDoc.open();
    iframeDoc.write(`
      <html>
        <head>
          <title>Print</title>
        </head>
        <body>${printContent}</body>
      </html>
    `);
    iframeDoc.close();

    // Trigger the print dialog
    iframe.contentWindow.focus();
    iframe.contentWindow.print();

    // Ensure the iframe is cleared and removed after printing
    iframe.contentWindow.onafterprint = () => {
      iframeDoc.body.innerHTML = ''; // Clear content
      document.body.removeChild(iframe); // Remove the iframe
    };
  };

  const getPetitionTypes = async () => {
    setIsPetitionTypesLoading(true);
    const endpoint = `${config.petitionForm}/Petitions`;
    try {
      const response = await fetch(endpoint, {
        method: 'GET',
        redirect: 'follow',
      });
      if (response.ok) {
        const petitionTypesData = await response.json();
        setPetitionTypes(petitionTypesData.Petitions);
        setIsPetitionTypesLoading(false);
      }
    } catch (error) {
      setIsPetitionTypesLoading(false);
      console.log(error);
    }
  };

  const handleLoadTemplate = async (value) => {
    if (!selectedPetitionType) return;
    setIsFormLoading(true);
    const endpoint = `${config.petitionForm}/${value.Endpoint}`;
    try {
      const response = await fetch(endpoint, {
        method: 'GET',
        redirect: 'follow',
      });
      if (response.ok) {
        const config = await response.json();
        setFormConfig(config);
        setIsFormLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const generatePetition = async () => {
    // Validate required fields
    const missingFields = formConfig.form.fields.filter(
      (field) => field.required && !formData[field.name]
    );

    if (missingFields.length > 0) {
      toast.info('Please fill all the required fields.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      return;
    }
    setIsGenerating(true);
    const newChatId = uuidv4();
    setPetitionId(newChatId);
    const currentTime = dayjs().format('DD-MM-YYYY');
    const date = formData.Date;
    let updatedFormData;
    if (date) {
      // Parse the date using dayjs
      const day = dayjs(date).date().toString();
      const year = dayjs(date).year().toString();
      const monthName = dayjs(date).format('MMMM');

      updatedFormData = {
        ...formData,
        user_id: userInfo._id,
        chat_id: newChatId,
        timecreated: currentTime,
        Category: selectedPetitionType.Name,
        Day: day,
        Month: monthName,
        Year: year,
      };
    }
    delete updatedFormData.Date;

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    const endpoint = `${config.petitionForm}/${selectedPetitionType.Endpoint}`;
    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(updatedFormData),
        redirect: 'follow',
      });
      if (response.ok) {
        const generatedPetition = await response.text();
        setGeneratedPetition(generatedPetition);
        setIsGenerating(false);
        setIsGenerateClicked(true);
      }
    } catch (error) {
      console.log(error);
      setIsGenerating(false);
    }
  };

  useEffect(() => {
    getPetitionTypes();
  }, []);

  useEffect(() => {
    if (titles) {
      setPetitionList(titles);
    }
    if (error) {
      toast.error(error, { position: 'top-right' });
    }
  }, [titles, error, dispatch]);

  useEffect(() => {
    dispatch(getPetitionTitles(userInfo?._id));
  }, [userInfo, dispatch, successDelete, generatedPetition]);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        backgroundColor: '#f5f5f5',
        height: 'calc(100vh - 50px)',
        flex: 5,
        p: 2,
        overflowY: 'auto',
      }}
    >
      {!isGenerateClicked && selectedPetitionTab === 0 && !isEditing && (
        <>
          {isPetitionTypesLoading ? (
            <CircularProgress
              size={20}
              sx={{
                mt: 4,
                display: 'flex',
                alignSelf: 'center',
                justifySelf: 'center',
              }}
            />
          ) : (
            <>
              <Typography variant="h5" sx={{ color: '#303f9f', mt: 2, mb: 2 }}>
                Create Petition
              </Typography>
              <Typography variant="subtitle1" sx={{ mb: 2 }}>
                Select a Petition Template
              </Typography>
              <TextField
                variant="outlined"
                label="Select a Petition Template"
                size="small"
                placeholder="Select a Petition Template"
                select
                sx={{ width: '30%', mb: 2, backgroundColor: 'white' }}
                onChange={(e) => {
                  setSelectedPetitionType(e.target.value);
                  handleLoadTemplate(e.target.value);
                }}
              >
                {petitionTypes.map((type) => (
                  <MenuItem key={type.Name} value={type}>
                    {type.Name}
                  </MenuItem>
                ))}
              </TextField>
            </>
          )}
          {!isFormLoading &&
          selectedPetitionType &&
          Object.keys(selectedPetitionType).length > 0 &&
          Object.keys(formConfig).length !== 0 ? (
            <Box>
              <Typography variant="h6" sx={{ color: '#303f9f', mb: 1 }}>
                {selectedPetitionType.Name}
              </Typography>
              <DynamicForm
                formConfig={formConfig}
                formData={formData}
                handleInputChange={handleInputChange}
                handleRadioChange={handleRadioChange}
              />
              <Button
                variant="contained"
                color="success"
                startIcon={!isGenerating && <SendIcon />}
                sx={{
                  mt: 3,
                  width: '14%',
                  textTransform: 'none',
                }}
                onClick={generatePetition}
              >
                {isGenerating ? (
                  <CircularProgress size={18} sx={{ color: '#fff' }} />
                ) : (
                  'Generate'
                )}
              </Button>
            </Box>
          ) : isFormLoading ? (
            <CircularProgress
              size={20}
              sx={{
                mt: 2,
                display: 'flex',
                alignSelf: 'center',
                justifySelf: 'center',
              }}
            />
          ) : (
            <></>
          )}
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </>
      )}
      {isGenerateClicked && selectedPetitionTab === 0 && !isEditing && (
        <>
          <Typography variant="h5" sx={{ color: '#303f9f', mt: 2, mb: 2 }}>
            Generated Petition
          </Typography>
          <Box
            sx={{
              display: 'flex',
              height: '75vh',
              backgroundColor: 'white',
              border: '2px solid #ccc',
            }}
          >
            <ReactQuill value={generatedPetition} readOnly theme="bubble" />
          </Box>
          <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 1, mt: 3 }}>
            <Button
              variant="contained"
              color="success"
              sx={{ textTransform: 'none' }}
              startIcon={<EditIcon fontSize="small" />}
              onClick={handleEdit}
            >
              Edit
            </Button>
            <Button
              variant="contained"
              color="warning"
              sx={{ textTransform: 'none' }}
              startIcon={<DownloadIcon fontSize="small" />}
              onClick={() =>
                downloadDocx(generatedPetition, selectedPetitionType.Name)
              }
            >
              Download
            </Button>
            <Button
              variant="contained"
              sx={{ textTransform: 'none' }}
              startIcon={<PrintIcon fontSize="small" />}
              onClick={handlePrint}
            >
              Print
            </Button>
            <Button
              variant="contained"
              sx={{
                textTransform: 'none',
                backgroundColor: '#303f9f',
                '&:hover': {
                  backgroundColor: '#303f9f',
                },
              }}
              startIcon={<ArrowBackIcon fontSize="small" />}
              onClick={handleBackToForm}
            >
              Back to Form
            </Button>
          </Stack>
        </>
      )}
      {isEditing && selectedPetitionTab === 0 && (
        <>
          <Box
            sx={{
              display: 'flex',
              height: '75vh',
              //backgroundColor: 'white',
            }}
          >
            <PetitionEditor
              initialContent={generatedPetition}
              onSave={getEditedContent}
            />
          </Box>
          <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 1, mt: 8 }}>
            <Button
              variant="contained"
              color="success"
              sx={{
                textTransform: 'none',
              }}
              startIcon={<SaveIcon fontSize="small" />}
              onClick={handleSave}
            >
              Save
            </Button>
            <Button
              variant="contained"
              sx={{
                textTransform: 'none',
                backgroundColor: '#303f9f',
                '&:hover': {
                  backgroundColor: '#303f9f',
                },
              }}
              startIcon={<ClearIcon fontSize="small" />}
              onClick={() => setIsEditing(false)}
            >
              Cancel
            </Button>
          </Stack>
        </>
      )}
      {selectedPetitionTab === 1 && (
        <>
          <Typography variant="h5" sx={{ color: '#303f9f', mt: 2, mb: 2 }}>
            Petition History
          </Typography>
          <Box sx={{ mb: 2 }}>
            <TextField
              variant="outlined"
              size="small"
              sx={{ width: '40%' }}
              placeholder="Search by Petition Title"
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: <SearchIcon sx={{ color: grey[500], mr: 1 }} />,
                sx: {
                  borderRadius: '30px',
                  fontSize: '14px',
                  backgroundColor: 'white',
                },
              }}
            />
          </Box>
          <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: 'bold',
                      backgroundColor: '#303f9f',
                      color: 'white',
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === 'Title'}
                      direction={orderBy === 'Title' ? order : 'asc'}
                      onClick={() => handleRequestSort('Title')}
                      sx={{
                        '& .MuiTableSortLabel-icon': {
                          color: 'white !important',
                        },
                      }}
                    >
                      <span
                        style={{
                          color: 'white',
                        }}
                      >
                        Petition Title
                      </span>
                      {orderBy === 'Title' ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'asc'
                            ? 'sorted ascending'
                            : 'sorted descending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 'bold',
                      backgroundColor: '#303f9f',
                      color: 'white',
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === 'ApplicantName'}
                      direction={orderBy === 'ApplicantName' ? order : 'asc'}
                      onClick={() => handleRequestSort('ApplicantName')}
                      sx={{
                        '& .MuiTableSortLabel-icon': {
                          color: 'white !important',
                        },
                      }}
                    >
                      <span
                        style={{
                          color: 'white',
                        }}
                      >
                        Petitioner Name
                      </span>
                      {orderBy === 'ApplicantName' ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'asc'
                            ? 'sorted ascending'
                            : 'sorted descending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 'bold',
                      backgroundColor: '#303f9f',
                      color: 'white',
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === 'Category'}
                      direction={orderBy === 'Category' ? order : 'asc'}
                      onClick={() => handleRequestSort('Category')}
                      sx={{
                        '& .MuiTableSortLabel-icon': {
                          color: 'white !important',
                        },
                      }}
                    >
                      <span
                        style={{
                          color: 'white',
                        }}
                      >
                        Category
                      </span>
                      {orderBy === 'Category' ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'asc'
                            ? 'sorted ascending'
                            : 'sorted descending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 'bold',
                      backgroundColor: '#303f9f',
                      color: 'white',
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === 'timecreated'}
                      direction={orderBy === 'timecreated' ? order : 'asc'}
                      onClick={() => handleRequestSort('timecreated')}
                      sx={{
                        '& .MuiTableSortLabel-icon': {
                          color: 'white !important',
                        },
                      }}
                    >
                      <span
                        style={{
                          color: 'white',
                        }}
                      >
                        Date Saved
                      </span>
                      {orderBy === 'timecreated' ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'asc'
                            ? 'sorted ascending'
                            : 'sorted descending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 'bold',
                      backgroundColor: '#303f9f',
                      color: 'white',
                    }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData && paginatedData.length > 0 ? (
                  paginatedData.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' },
                      }}
                    >
                      <TableCell sx={{ padding: '8px 16px', fontSize: '14px' }}>
                        {row.Title}
                      </TableCell>
                      <TableCell sx={{ padding: '8px 16px', fontSize: '14px' }}>
                        {row.ApplicantName}
                      </TableCell>
                      <TableCell sx={{ padding: '8px 16px', fontSize: '14px' }}>
                        {row.Category}
                      </TableCell>
                      <TableCell sx={{ padding: '8px 16px', fontSize: '14px' }}>
                        {row.timecreated}
                      </TableCell>
                      <TableCell sx={{ padding: '8px 16px', fontSize: '14px' }}>
                        <CustomTooltip title="Preview">
                          <IconButton
                            sx={{ color: '#2196f3' }}
                            onClick={() => handlePreview(row.Document)}
                          >
                            <VisibilityIcon fontSize="small" />
                          </IconButton>
                        </CustomTooltip>
                        <CustomTooltip title="Edit">
                          <IconButton
                            sx={{ color: [green[500]] }}
                            onClick={() =>
                              handleEditPetition(row.chat_id, row.Document)
                            }
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </CustomTooltip>
                        <CustomTooltip title="Download">
                          <IconButton
                            sx={{ color: [orange[500]] }}
                            onClick={() =>
                              downloadDocx(row.Document, row.Title)
                            }
                          >
                            <DownloadIcon fontSize="small" />
                          </IconButton>
                        </CustomTooltip>
                        <CustomTooltip title="Delete">
                          <IconButton
                            sx={{ color: [red[500]] }}
                            onClick={(e) =>
                              handleDeletePetition(e, row.chat_id)
                            }
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </CustomTooltip>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      sx={{
                        textAlign: 'center',
                        padding: '16px',
                        fontSize: '14px',
                        color: '#777',
                      }}
                    >
                      No petitions found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <PetitionPreview
              open={showPreview}
              onClose={handleClosePreview}
              content={previewContent}
            />
            <TablePagination
              sx={{ marginRight: 5 }}
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={getFilteredData().length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </>
      )}
    </Box>
  );
};

export default PetitionForm;
